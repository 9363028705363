import axios from "axios"
// https://backend.tonlottery.com/auth/login

export const Login = (data) => {
    return async (dispatch) => {
        await axios.post('https://staging.tonlottery.com/auth/login', data)
            .then(res => dispatch({
                type: 'login',
                payload: res
            }))
            .catch(error =>
                console.log(error.message)
            )
    }
}