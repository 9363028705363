import './style.css'

export const HomeRoulette = () => {
    return (
        <div className='homeRoulette'>
            <div className='homeRouletteLeftPart'>
                <div className='homeRouletteLeftTopPart'>
                    <h3>TON Roulette</h3>
                    <h4>Get a chance of winning an exclusive NFT</h4>
                </div>
                <div className='homeRouletteLeftBottomPart'>
                    <button className='homeRouletteRightPart'>Make a bet</button>
                </div>
            </div>
            <div className='chart'>
                <img alt='' src={require('../../public/chart.png')} />
                <div className='homeRouletteRightPart chartBtn'>
                    <span>Stake</span>
                    <img alt='' src={require('../../public/fire.png')} />
                </div>
            </div>
        </div>
    )
}