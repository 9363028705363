import './style.css'
import { RouletteDiamond } from '../svg'

export const MyUpcomingTickets = () => {
    const tickets = [
        {
            image: '1.png',
            title: 'G-BOT #4390',
            description: 'Emerald eyed G-BOT with a Golden Outer Body, Plastic Inner Body and Plastic Elements on Oxley Background. Featuring Origin Full Progenitor Set',
            cryptoAmount: '45',
            soldAmount: '17 / 45',
            id: '1',
            myTicketCount: '4'
        },
        {
            image: '1.png',
            title: 'G-BOT #4390',
            description: 'Emerald eyed G-BOT with a Golden Outer Body, Plastic Inner Body and Plastic Elements on Oxley Background. Featuring Origin Full Progenitor Set',
            cryptoAmount: '45',
            soldAmount: '17 / 45',
            id: '1',
            myTicketCount: '0'
        },
        {
            image: 'Group161.png',
            title: 'TON COIN Raffle',
            description: 'Emerald eyed G-BOT with a Golden Outer Body, Plastic Inner Body and Plastic Elements on Oxley Background. Featuring Origin Full Progenitor Set',
            cryptoAmount: '45',
            soldAmount: '17 / 45',
            id: '3',
            myTicketCount: '5'
        },
    ]

    return (
        <div className='MyUpcomingTickts'>
            {tickets.length > 0 && tickets.map((e, i) => (
                <div className='mobileUpcoming' key={i}>
                    <div className='eachUpcomingTicket'>
                        <div className='mobUp' key={i}>
                            <img className='upcomingimg' alt='' src={require(`../../public/${e.image}`)} />
                            <div className='upcomingRight'>
                                <h2>{e?.title}</h2>
                                <p>{e?.description}</p>
                                <div className='upcomingCryptos'>
                                    <div className='eachUpcomingCrypto'>
                                        <div className='cryptoIcon upcomingCrypto'>
                                            <RouletteDiamond />
                                            <span>{e.cryptoAmount}</span>
                                        </div>
                                        <p>NFT price</p>
                                    </div>
                                    <div className='eachUpcomingCrypto'>
                                        <div className='cryptoIcon upcomingCrypto'>
                                            <span>{e.soldAmount}</span>
                                        </div>
                                        <p>Tickets sold</p>
                                    </div>
                                    <div className='eachUpcomingCrypto'>
                                        <div className='cryptoIcon upcomingCrypto'>
                                            <span>{e.myTicketCount}</span>
                                        </div>
                                        <p>Your tickets</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='upcomingCryptos mobileUpcomingCryptos'>
                            <div className='eachUpcomingCrypto'>
                                <div className='cryptoIcon upcomingCrypto'>
                                    <RouletteDiamond />
                                    <span>{e.cryptoAmount}</span>
                                </div>
                                <p>NFT price</p>
                            </div>
                            <div className='eachUpcomingCrypto'>
                                <div className='cryptoIcon upcomingCrypto'>
                                    <span>{e.soldAmount}</span>
                                </div>
                                <p>Tickets sold</p>
                            </div>
                            <div className='eachUpcomingCrypto'>
                                <div className='cryptoIcon upcomingCrypto'>
                                    <span>{e.myTicketCount}</span>
                                </div>
                                <p>Your tickets</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}