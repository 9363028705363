import { DiamondFund, NavigationLogo } from '../svg'
import './style.css'

export const Footer = () => {
    return (
        <div className='footer'>
            <div className='mainFooter'>
                <div className='leftFooter'>
                    <NavigationLogo />
                    <p>TON Lottery is a one-stop-shop for all your lottery and gaming needs.
                        Play classic lottery games, NFT raffles, and other exciting game
                        modes all on the secure TON blockchain. With instant payouts for
                        winners and real-time results, TON Lottery is the perfect choice for
                        anyone looking for a fun and fair gaming experience. Join now and see for yourself!
                        <br /> <br />
                        You can write us at: <span className='whiteFooter'>lucky@tonlottery.com</span>
                    </p>
                    <h3>Don't miss airdrops and closed raffles!</h3>
                    <p>We will notify you when there'll be a chance for you to earn some NFT of TON prizes</p>
                    <div className='profInputs footerEmail'>
                        <input placeholder='Your email' />
                        <button>OK</button>
                    </div>
                </div>
                <div className='rightFooter'>
                    <div className='eachColumn'>
                        <p>Menu</p>
                        <span onClick={() => window.location = '/'}>Home page</span>
                        <span>My tickets</span>
                        <span>Help</span>
                        <span onClick={() => window.location = '/profile'}>My profile</span>
                        <span>About</span>
                        <span>Blog</span>
                        <span>Airdrops</span>
                        <span>NFT Club</span>
                    </div>
                    <div className='eachColumn'>
                        <p>Community</p>
                        <span>Telegram Channel</span>
                        <span>Main chat English</span>
                        <span>Chat Russian</span>
                        <span>Chat Hindi</span>
                        <span>Chat Portuguese</span>
                        <span>Chat Vietnamese</span>
                        <span>Chat Indonesian</span>
                    </div>
                    <div className='eachColumn'>
                        <p>Games</p>
                        <span>PvP Roll</span>
                        <span>NFT/TON Raffle</span>
                        <span>Daily Lottery</span>
                        <span>Energy Games</span>
                        <span>Private PvP Roll</span>
                        <span>VIP Raffle</span>
                    </div>
                </div>
            </div>
            <div className='footerBottom'>
                <div className='basedOn'>
                    <span>Based on</span>
                    <DiamondFund />
                    <span>The Open Network</span>
                </div>
                <span>2023, TON Lottery. Cookies policy, terms and conditions, privacy policy</span>
            </div>
        </div>
    )
}