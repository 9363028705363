import './style.css'
import { GreaterThan, RouletteDiamond } from '../svg'

export const ActiveRoulettes = () => {
    const roulettes = [
        {
            image: '1.png',
            title: 'G-BOT #4390',
            description: 'Emerald eyed G-BOT with a Golden Outer Body, Plastic Inner Body and Plastic Elements on Oxley Background. Featuring Origin Full Progenitor Set',
            cryptoAmount: '45',
            cryptoText: 'NFT price',
            ticketAmount: '1.1',
            ticketText: 'Ticket price',
            soldAmount: '10 / 45',
            soldText: 'Tickets sold',
            id: '1',
            myTicketCount: '4'
        },
        {
            image: '1.png',
            title: 'G-BOT #4390',
            description: 'Emerald eyed G-BOT with a Golden Outer Body, Plastic Inner Body and Plastic Elements on Oxley Background. Featuring Origin Full Progenitor Set',
            cryptoAmount: '45',
            cryptoText: 'NFT price',
            ticketAmount: '1.1',
            ticketText: 'Ticket price',
            soldAmount: '10 / 45',
            soldText: 'Tickets sold',
            id: '2',
            myTicketCount: '0'
        },
        {
            image: 'Group161.png',
            title: 'TON COIN Raffle',
            description: 'Emerald eyed G-BOT with a Golden Outer Body, Plastic Inner Body and Plastic Elements on Oxley Background. Featuring Origin Full Progenitor Set',
            cryptoAmount: '45',
            cryptoText: 'Total pool',
            ticketAmount: '1.1',
            ticketText: 'Ticket price',
            soldAmount: '10 / 45',
            soldText: 'Tickets sold',
            id: '3',
            myTicketCount: '5'
        },
    ]

    return (
        <div className='activeRoulettes'>
            {roulettes.length > 0 && roulettes.map((e, i) => (
                <div className='eachActiveRoulette' key={i}>
                    <img alt='' src={require(`../../public/${e.image}`)} />
                    <div className='activeRight'>
                        <h2 onClick={() => window.location = `/raffle/${e?.id}`}>{e.title}</h2>
                        <p>{e.description}</p>
                        <div className='activeCryptos'>
                            <div className='eachActiveCrypto'>
                                <div className='cryptoIcon'>
                                    <RouletteDiamond />
                                    <span>{e.cryptoAmount}</span>
                                </div>
                                <p>{e.cryptoText}</p>
                            </div>
                            <div className='eachActiveCrypto'>
                                <div className='cryptoIcon'>
                                    <RouletteDiamond />
                                    <span>{e.ticketAmount}</span>
                                </div>
                                <p>{e.ticketText}</p>
                            </div>
                            <div className='eachActiveCrypto'>
                                <div className='cryptoIcon'>
                                    <span>{e.soldAmount}</span>
                                </div>
                                <p>{e.soldText}</p>
                            </div>
                        </div>
                        <div className='activeTickets '>
                            <button className='greenButton'>Buy tickets <GreaterThan/> </button>
                            {e.myTicketCount > 0 && <span>You have {e.myTicketCount} tickets</span>}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}