export const NavigationLogo = () => {
    return (
        <svg width="105" height="32" viewBox="0 0 105 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_326_2999)">
                <path fillRule="evenodd" clipRule="evenodd" d="M4.75326 8.21693e-07H12.4388C12.941 -1.60359e-05 13.3754 -3.28149e-05 13.7208 0.0283216C14.0708 0.0570381 14.4705 0.122631 14.8142 0.345993C15.2786 0.647783 15.5782 1.11168 15.6383 1.62236C15.6828 2.00033 15.5466 2.34277 15.3995 2.62827C15.2543 2.91013 15.0373 3.24665 14.7863 3.6358L9.3306 12.0951C9.17911 12.3299 8.89909 12.4746 8.59606 12.4746C8.29298 12.4746 8.01296 12.3299 7.86147 12.0951L2.40578 3.63582C2.15477 3.24666 1.93771 2.91013 1.79251 2.62827C1.64545 2.34277 1.50924 2.00033 1.55375 1.62236C1.61388 1.11168 1.91339 0.647783 2.37785 0.345993C2.7216 0.122631 3.12124 0.0570381 3.47119 0.0283216C3.81667 -3.28149e-05 4.251 -1.60359e-05 4.75326 8.21693e-07ZM3.3659 1.57937C3.29814 1.62654 3.2536 1.69552 3.24179 1.77159C3.24448 1.78619 3.25873 1.84937 3.33289 1.99334C3.44151 2.20419 3.61832 2.47987 3.89423 2.90767L7.74801 8.88309V1.51718H4.792C4.23989 1.51718 3.88459 1.51794 3.62614 1.53915C3.44966 1.55363 3.38138 1.57417 3.3659 1.57937ZM9.44406 1.51718V8.88309L13.2978 2.90767C13.5737 2.47987 13.7505 2.20419 13.8591 1.99334C13.9333 1.84937 13.9475 1.78619 13.9503 1.77159C13.9385 1.69552 13.8939 1.62654 13.8262 1.57937C13.8107 1.57417 13.7424 1.55363 13.5659 1.53915C13.3075 1.51794 12.9521 1.51718 12.4001 1.51718H9.44406Z" fill="#27BEFF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M3.66308 18.0006C0.0913652 20.4594 -1.06011 25.3377 2.02388 28.9972C4.40071 31.8175 7.32203 32.5396 10.3895 31.4319C11.8184 30.9159 13.0755 29.2348 13.5527 28.3044C14.1089 27.2199 14.1827 26.3074 14.5013 25.1714C14.555 24.98 14.6423 24.7014 14.7929 24.8189C14.9436 24.9365 15.0057 26.1821 14.8421 26.776C15.7955 26.0299 16.1424 25.0141 16.2047 23.825C16.2444 23.0648 15.7251 22.4507 14.8421 22.1555C13.1931 21.6043 10.9763 24.3985 10.8967 24.2348C10.8172 24.0711 11.2391 23.4151 11.7323 22.6498C12.2256 21.8845 13.2536 20.6556 13.329 20.9509C13.4044 21.2462 13.0825 21.6764 12.7965 22.1555C13.1485 21.8529 13.8248 21.5254 14.2325 21.5125C14.5597 21.1298 14.6393 20.806 14.656 20.2299C14.6813 19.3574 14.1242 18.6001 13.1151 18.2393C10.4376 17.2818 7.59913 20.1627 7.0535 21.7144C6.50847 20.9509 8.50776 17.8369 11.379 17.4733C12.5499 16.4899 13.6128 15.4523 14.2325 14.4177C14.5442 13.8973 14.6986 13.1861 14.656 12.8254C14.6169 12.4944 14.2946 12.1979 13.7607 11.9902C13.5833 11.9211 13.3097 11.9105 13.0054 11.9671C12.7054 12.023 12.4266 12.1351 12.2523 12.2598L8.39083 15.0216L3.66308 18.0006ZM4.01431 21.152C4.62132 24.3982 7.78055 26.2919 10.3895 27.1035C6.74581 26.5624 4.01431 25.2098 4.01431 21.152Z" fill="white" />
                <path d="M6.97671 13.7673L7.78056 14.7435L4.33417 16.9152C4.36388 16.6816 4.34526 16.4399 4.29546 16.1992C4.16423 15.5651 3.80716 14.8832 3.48826 14.2742L3.48463 14.2673C3.15136 13.6309 2.8708 13.09 2.81639 12.6764C2.79034 12.4784 2.82243 12.3477 2.88964 12.258C2.95531 12.1703 3.08853 12.0766 3.37422 12.0165C4.04551 11.8754 4.70901 12.027 5.33205 12.3637C5.95754 12.7017 6.52388 13.2175 6.97671 13.7673Z" fill="white" />
                <path d="M24.5724 13.0168V2.86354H20.8821V0.442383H31.4063V2.86354H27.716V13.0168H24.5724Z" fill="#27BEFF" />
                <path d="M38.5042 13.2125C36.4606 13.2125 34.905 12.6918 33.8377 11.6504C32.7703 10.6091 32.2366 8.98846 32.2366 6.7886C32.2366 4.45854 32.7703 2.78587 33.8377 1.77054C34.905 0.755222 36.4606 0.247559 38.5042 0.247559C40.5479 0.247559 42.1034 0.755222 43.1708 1.77054C44.2382 2.78587 44.7719 4.45854 44.7719 6.7886C44.7719 8.98846 44.2382 10.6091 43.1708 11.6504C42.1034 12.6918 40.5479 13.2125 38.5042 13.2125ZM38.5042 10.5375C39.5586 10.5375 40.3266 10.2381 40.8082 9.63931C41.3029 9.02752 41.5502 8.07728 41.5502 6.7886C41.5502 5.38275 41.3029 4.39346 40.8082 3.82071C40.3266 3.23495 39.5586 2.94207 38.5042 2.94207C37.4368 2.94207 36.6558 3.23495 36.1612 3.82071C35.6796 4.39346 35.4387 5.38275 35.4387 6.7886C35.4387 8.07728 35.6796 9.02752 36.1612 9.63931C36.6558 10.2381 37.4368 10.5375 38.5042 10.5375Z" fill="#27BEFF" />
                <path d="M46.375 13.0168V0.442383H49.0695L54.4195 7.95969V0.442383H57.5826V13.0168H54.8686L49.5186 5.51902V13.0168H46.375Z" fill="#27BEFF" />
                <path d="M24.5571 30.9152V18.3408H27.7007V28.494H33.6364V30.9152H24.5571Z" fill="white" />
                <path d="M40.5402 31.1104C38.4965 31.1104 36.941 30.5897 35.8736 29.5484C34.8062 28.507 34.2725 26.8864 34.2725 24.6865C34.2725 22.3565 34.8062 20.6838 35.8736 19.6685C36.941 18.6532 38.4965 18.1455 40.5402 18.1455C42.5838 18.1455 44.1393 18.6532 45.2067 19.6685C46.2741 20.6838 46.8078 22.3565 46.8078 24.6865C46.8078 26.8864 46.2741 28.507 45.2067 29.5484C44.1393 30.5897 42.5838 31.1104 40.5402 31.1104ZM40.5402 28.4354C41.5945 28.4354 42.3625 28.136 42.8441 27.5372C43.3388 26.9254 43.5861 25.9752 43.5861 24.6865C43.5861 23.2807 43.3388 22.2914 42.8441 21.7187C42.3625 21.1329 41.5945 20.84 40.5402 20.84C39.4728 20.84 38.6917 21.1329 38.1971 21.7187C37.7155 22.2914 37.4747 23.2807 37.4747 24.6865C37.4747 25.9752 37.7155 26.9254 38.1971 27.5372C38.6917 28.136 39.4728 28.4354 40.5402 28.4354Z" fill="white" />
                <path d="M51.1295 30.9152V20.762H47.4392V18.3408H57.9634V20.762H54.2731V30.9152H51.1295Z" fill="white" />
                <path d="M62.8751 30.9152V20.762H59.1848V18.3408H69.709V20.762H66.0187V30.9152H62.8751Z" fill="white" />
                <path d="M71.1208 30.9152V18.3408H80.3954V20.762H74.2644V23.3979H79.6729V25.8581H74.2644V28.494H80.3954V30.9152H71.1208Z" fill="white" />
                <path d="M81.894 30.9152V18.3408H88.1812C89.6651 18.3408 90.7585 18.7313 91.4614 19.5123C92.1643 20.2934 92.5158 21.3412 92.5158 22.6559C92.5158 23.502 92.301 24.244 91.8715 24.8818C91.4419 25.5197 90.8561 25.9948 90.1142 26.3072C90.2964 26.4504 90.4396 26.6131 90.5437 26.7953C90.6609 26.9645 90.7845 27.2054 90.9147 27.5178L92.3986 30.9152H89.1184L87.7125 27.713C87.5954 27.4397 87.4457 27.2444 87.2635 27.1273C87.0812 26.9971 86.8209 26.932 86.4824 26.932H85.0376V30.9152H81.894ZM85.0376 24.5109H87.3611C87.9729 24.5109 88.448 24.3612 88.7864 24.0618C89.1379 23.7494 89.3136 23.2808 89.3136 22.6559C89.3136 21.3933 88.7083 20.762 87.4978 20.762H85.0376V24.5109Z" fill="white" />
                <path d="M96.9725 30.9152V26.2877L92.4621 18.3408H95.7424L98.554 23.5541L101.366 18.3408H104.646L100.136 26.2877V30.9152H96.9725Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_326_2999">
                    <rect width="104.678" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const DiamondFund = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.18494 9.22175e-07H10.8151C11.3137 -1.79967e-05 11.7449 -3.68285e-05 12.0879 0.0317848C12.4353 0.0640132 12.8321 0.137627 13.1734 0.388302C13.6344 0.726998 13.9318 1.24762 13.9915 1.82075C14.0357 2.24494 13.9005 2.62925 13.7545 2.94966C13.6103 3.26599 13.3949 3.64366 13.1457 4.0804L7.72929 13.5741C7.57887 13.8377 7.30085 14 7.00001 14C6.69912 14 6.42112 13.8377 6.27076 13.5741L0.85439 4.08041C0.605195 3.64367 0.389694 3.26599 0.24555 2.94966C0.0995447 2.62925 -0.0356752 2.24494 0.00850629 1.82075C0.0682069 1.24762 0.36556 0.726998 0.826663 0.388302C1.16794 0.137627 1.56469 0.0640132 1.91212 0.0317848C2.25511 -3.68285e-05 2.68631 -1.79967e-05 3.18494 9.22175e-07ZM1.80759 1.7725C1.74032 1.82544 1.6961 1.90285 1.68438 1.98823C1.68705 2.00461 1.70119 2.07551 1.77482 2.23709C1.88265 2.47373 2.05819 2.78312 2.33211 3.26323L6.15809 9.96932V1.7027H3.22341C2.67528 1.7027 2.32254 1.70356 2.06595 1.72736C1.89075 1.74362 1.82296 1.76666 1.80759 1.7725ZM7.84192 1.7027V9.96932L11.6679 3.26323C11.9418 2.78312 12.1173 2.47373 12.2252 2.23709C12.2988 2.07551 12.3129 2.00461 12.3156 1.98823C12.3039 1.90285 12.2597 1.82544 12.1924 1.7725C12.1771 1.76666 12.1093 1.74362 11.9341 1.72736C11.6775 1.70356 11.3247 1.7027 10.7766 1.7027H7.84192Z" fill="#1FA0FD" />
        </svg>
    )
}

export const LightningFund = () => {
    return (
        <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.4342 6.61077H5.23201L7.38015 0.265632C7.38184 0.212397 7.36504 0.159999 7.33205 0.115609C7.29906 0.0712184 7.25149 0.0370067 7.19586 0.0176563C7.14023 -0.00169413 7.07926 -0.00523742 7.02128 0.00751146C6.96331 0.0202603 6.91117 0.0486782 6.872 0.0888752L0.103398 8.70013C0.0432469 8.77742 0.00790261 8.8679 0.00117826 8.9618C-0.00554608 9.0557 0.0166046 9.14945 0.0652407 9.23294C0.113877 9.31643 0.187152 9.3865 0.277159 9.43557C0.367167 9.48464 0.470489 9.51086 0.57598 9.5114H5.7578L3.61982 15.7296C3.61701 15.7833 3.63301 15.8363 3.66559 15.8815C3.69816 15.9266 3.74569 15.9616 3.80158 15.9815C3.85746 16.0015 3.91892 16.0054 3.97742 15.9927C4.03591 15.9801 4.08853 15.9515 4.12797 15.9109L10.9067 7.40844C10.9628 7.3313 10.9948 7.2422 10.9994 7.15035C11.004 7.05849 10.9811 6.96721 10.9329 6.88591C10.8847 6.80462 10.8131 6.73626 10.7254 6.68789C10.6378 6.63953 10.5372 6.6129 10.4342 6.61077Z" fill="#61FF00" />
        </svg>
    )
}

export const CloseIcon = () => {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L9 9M1 9L9 1L1 9Z" stroke="white" strokeOpacity="0.935" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const ArrowDown = () => {
    return (
        <svg width="10" height="10" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.599 1.437L1.376 0.652999L3.91 3.229L6.444 0.653L7.221 1.437L3.91 4.748L0.599 1.437Z" fill="white" />
        </svg>
    )
}

export const TelegramIcon = () => {
    return (
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.36937 6.90067C6.73698 4.64981 10.3103 3.15417 12.1046 2.42856C17.2115 0.370208 18.285 0.0148083 18.9751 0C19.1285 0 19.4659 0.0296168 19.6959 0.207316C19.8799 0.355399 19.926 0.547908 19.9566 0.695991C19.9873 0.844074 20.018 1.15505 19.9873 1.39198C19.7112 4.20556 18.515 11.0322 17.9016 14.1715C17.6409 15.5043 17.1348 15.9485 16.644 15.993C15.5705 16.0818 14.7577 15.3118 13.7302 14.6602C12.1046 13.6384 11.1998 13.0017 9.62015 11.9947C7.79516 10.8397 8.97604 10.2029 10.0189 9.16634C10.2949 8.89979 15.0031 4.75347 15.0951 4.38326C15.1104 4.33883 15.1104 4.16113 15.0031 4.07228C14.8957 3.98343 14.7424 4.01305 14.6197 4.04267C14.451 4.07228 11.8745 5.73081 6.85967 9.00345C6.12354 9.49212 5.46409 9.72905 4.86598 9.71425C4.20654 9.69944 2.94898 9.35885 1.99815 9.06268C0.847949 8.70728 -0.0722116 8.51477 0.00446848 7.89282C0.0504765 7.56704 0.510557 7.24126 1.36937 6.90067Z" fill="white" />
        </svg>
    )
}

export const BigDiamond = () => {
    return (
        <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.41735 2.43718e-06H28.5827C29.9004 -4.75628e-05 31.0401 -9.73325e-05 31.9465 0.0840027C32.8647 0.169178 33.9134 0.363728 34.8153 1.02623C36.0339 1.92135 36.8198 3.29728 36.9775 4.81198C37.0942 5.93305 36.737 6.94873 36.3511 7.79553C35.9701 8.63155 35.4007 9.62968 34.7421 10.7839L20.4274 35.8745C20.0299 36.571 19.2951 37 18.5 37C17.7048 37 16.9701 36.571 16.5727 35.8745L2.25803 10.784C1.59944 9.6297 1.02991 8.63155 0.648954 7.79553C0.263083 6.94873 -0.0942844 5.93305 0.0224809 4.81198C0.180261 3.29728 0.966122 1.92135 2.18475 1.02623C3.08669 0.363728 4.13525 0.169178 5.05345 0.0840027C5.95994 -9.73325e-05 7.09953 -4.75628e-05 8.41735 2.43718e-06ZM4.7772 4.68448C4.59942 4.82438 4.48256 5.02895 4.45158 5.2546C4.45862 5.2979 4.49601 5.48528 4.6906 5.9123C4.97558 6.5377 5.4395 7.35538 6.16343 8.62425L16.275 26.3475V4.5H8.51901C7.07038 4.5 6.13814 4.50228 5.46002 4.56518C4.99699 4.60813 4.81782 4.66903 4.7772 4.68448ZM20.7251 4.5V26.3475L30.8367 8.62425C31.5605 7.35538 32.0243 6.5377 32.3094 5.9123C32.504 5.48528 32.5413 5.2979 32.5485 5.2546C32.5176 5.02895 32.4006 4.82438 32.2229 4.68448C32.1823 4.66903 32.0031 4.60813 31.54 4.56518C30.8619 4.50228 29.9296 4.5 28.4811 4.5H20.7251Z" fill="#1FA0FD" />
        </svg>
    )
}

export const BigLightning = () => {
    return (
        <svg width="28" height="41" viewBox="0 0 28 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.5597 16.9401H13.3178L18.7858 0.680683C18.7901 0.544268 18.7474 0.409997 18.6634 0.296247C18.5794 0.182497 18.4583 0.0948296 18.3167 0.0452442C18.1751 -0.0043412 18.0199 -0.0134209 17.8724 0.0192481C17.7248 0.0519171 17.5921 0.124738 17.4924 0.227743L0.263194 22.2941C0.110083 22.4921 0.0201157 22.724 0.00299922 22.9646C-0.0141173 23.2052 0.0422662 23.4455 0.166067 23.6594C0.289868 23.8734 0.476387 24.0529 0.705497 24.1786C0.934606 24.3044 1.19761 24.3716 1.46613 24.373H14.6562L9.21408 40.3072C9.20694 40.4446 9.24767 40.5806 9.33058 40.6963C9.4135 40.812 9.53449 40.9016 9.67674 40.9527C9.819 41.0037 9.97544 41.0138 10.1243 40.9813C10.2732 40.9489 10.4072 40.8757 10.5076 40.7717L27.7626 18.9841C27.9054 18.7865 27.9868 18.5581 27.9985 18.3228C28.0102 18.0874 27.9518 17.8535 27.8292 17.6452C27.7066 17.4368 27.5243 17.2617 27.3011 17.1377C27.078 17.0138 26.822 16.9456 26.5597 16.9401Z" fill="#61FF00" />
        </svg>
    )
}

export const RouletteDiamond = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.00491 1.44913e-06H16.9951C17.7786 -2.82806e-05 18.4563 -5.78734e-05 18.9952 0.0499475C19.5412 0.100592 20.1647 0.21627 20.701 0.610189C21.4255 1.14243 21.8929 1.96054 21.9866 2.86118C22.056 3.52776 21.8436 4.13168 21.6141 4.63518C21.3876 5.13227 21.0491 5.72575 20.6575 6.41205L12.146 21.3308C11.9097 21.7449 11.4728 22 11 22C10.5272 22 10.0903 21.7449 9.85405 21.3308L1.34261 6.41208C0.95102 5.72577 0.612377 5.13227 0.385865 4.63518C0.156427 4.13168 -0.056061 3.52776 0.013367 2.86118C0.107182 1.96054 0.574451 1.14243 1.29904 0.610189C1.83533 0.21627 2.4588 0.100592 3.00476 0.0499475C3.54375 -5.78734e-05 4.22134 -2.82806e-05 5.00491 1.44913e-06ZM2.8405 2.78536C2.73479 2.86855 2.6653 2.99019 2.64688 3.12436C2.65107 3.1501 2.6733 3.26152 2.789 3.51542C2.95845 3.88728 3.2343 4.37347 3.66474 5.12793L9.67701 15.6661V2.67568H5.06536C4.20401 2.67568 3.6497 2.67703 3.2465 2.71443C2.97118 2.73997 2.86465 2.77618 2.8405 2.78536ZM12.323 2.67568V15.6661L18.3353 5.12793C18.7657 4.37347 19.0415 3.88728 19.211 3.51542C19.3267 3.26152 19.3489 3.1501 19.3531 3.12436C19.3348 2.99019 19.2652 2.86855 19.1595 2.78536C19.1354 2.77618 19.0289 2.73997 18.7535 2.71443C18.3503 2.67703 17.796 2.67568 16.9347 2.67568H12.323Z" fill="#1FA0FD" />
        </svg>
    )
}

export const PlusSign = () => {
    return (
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.84 14.728V8.808H0.92V6.12H6.84V0.231998H9.528V6.12H15.432V8.808H9.528V14.728H6.84Z" fill="white" />
        </svg>
    )
}

export const MinusSign = () => {
    return (
        <svg width="14" height="3" viewBox="0 0 14 3" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.2 2.53V0.85H13.4V2.53H0.2Z" fill="white" />
        </svg>
    )
}

export const GreaterThan = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_226_2438)">
                <path d="M6.31469 12.54L4.63469 10.86L7.91469 7.7L4.63469 4.54L6.31469 2.86L11.1547 7.7L6.31469 12.54Z" fill="white" />
            </g>
            <defs>
                <filter id="filter0_d_226_2438" x="0.634766" y="0.860352" width="14.52" height="17.6797" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_226_2438" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_226_2438" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export const ArrowRight = () => {
    return (
        <svg width="18" height="7" viewBox="0 0 18 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.84 4.171V2.995H10.08V4.171H0.84ZM14.4448 6.929L13.5278 6.019L16.0128 3.59L13.5278 1.161L14.4448 0.251L17.7768 3.59L14.4448 6.929Z" fill="#0EFF00" />
        </svg>
    )
}

export const Cancel = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14ZM7.74244 6.99992L9.97225 4.77012C10.2456 4.49676 10.1233 4.18352 9.96983 4.03008C9.81639 3.87664 9.50315 3.7543 9.22978 4.02766L6.99998 6.25746L4.7726 4.03008C4.48539 3.74287 4.18599 3.87422 4.03013 4.03008C3.87428 4.18593 3.71439 4.45679 4.03013 4.77254L6.25752 6.99992L4.03255 9.2249C3.75918 9.49826 3.87669 9.81633 4.03013 9.96977C4.18357 10.1232 4.50164 10.2407 4.77501 9.96736L6.99998 7.74239L9.22737 9.96977C9.4996 10.242 9.8188 10.1208 9.97225 9.96736C10.1257 9.81392 10.2421 9.49954 9.96983 9.22731L7.74244 6.99992Z" fill="#4F5563" />
        </svg>
    )
}

export const BlueGreaterThan = () => {
    return (
        <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.67027 6.95L0.627266 5.907L3.01427 3.59L0.627266 1.273L1.67027 0.23L5.03727 3.59L1.67027 6.95Z" fill="#27BEFF" />
        </svg>
    )
}

export const GreyGreaterThan = () => {
    return (
        <svg width="4" height="7" viewBox="0 0 4 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.713578 6.058L0.0415782 5.392L2.24958 3.22L0.0415782 1.048L0.713578 0.382L3.55158 3.22L0.713578 6.058Z" fill="#868686" />
        </svg>
    )
}