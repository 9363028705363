import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { StartGame } from '../../Redux/action/gameAction'
import { BlueGreaterThan, Cancel, DiamondFund } from '../svg'

export const MyBet = () => {
    const dispatch = useDispatch()
    const [betStake, setBetStake] = useState(0.1)

    function stake() {
        dispatch(StartGame(betStake))
    }

    return (
        <div className='betOptions'>
            <span>Waiting for 1 more player...</span>
            <div className='betBg'>
                <div className='myBet'>
                    <span>Your bet</span>
                    <span>$2.27</span>
                </div>
                <span className='winChance'><DiamondFund />1</span>
                <div className='upMyBet'>
                    <div className='inputBet'>
                        <div className='myBetInput'>
                            <input placeholder='Your value' value={betStake} onChange={(e) => setBetStake(e.target.value)} />
                            <div className='cancelBet' onClick={() => setBetStake('')} >
                                <Cancel />
                            </div>
                        </div>
                        <div className='upBet' onClick={() => setBetStake(+betStake + 0.1)}>
                            <span>+0.1</span>
                        </div>
                        <div className='upBet' onClick={() => setBetStake(+betStake + 1)}>
                            <span>+1</span>
                        </div>
                        <div className='upBet' onClick={() => setBetStake(+betStake + 5)}>
                            <span>+5</span>
                        </div>
                    </div>
                </div>
                <div className='submitMyBet'>
                    <button className='stakeMyBet greenButton' onClick={stake}>Stake <span><img alt='' src={require('../../public/fire.png')} /></span></button>
                    <div className='myBetBalance'>
                        <p>Your balance: 15.44 TON</p>
                        <span>Deposit <BlueGreaterThan /></span>
                    </div>
                </div>
            </div>
        </div>
    )
}