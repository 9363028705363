import './style.css'
import { TelegramIcon } from '../svg'

export const Hamburger = ({ user }) => {
    return (
        <div className='hamburger'>
            {Object.keys(user).length > 0
                ? <div className='dynamicHamButton'>
                    <span>My profile</span>
                </div>
                : <div className='shareYourLuck'>
                    <TelegramIcon />
                    <span>Share your luck</span>
                </div>
            }
            <h1>Games</h1>
            <div className='hamGames'>
                <div className='eachHamGame'>
                    <span>PvP Roll</span>
                </div>
                <div className='eachHamGame'>
                    <span>NFT/TON Raffle</span>
                </div>
                <div className='eachHamGame'>
                    <span>Daily Lottery</span>
                </div>
                <div className='eachHamGame'>
                    <span>Energy Games</span>
                </div>
                <div className='eachHamGame'>
                    <span>Private PvP Roll</span>
                </div>
                <div className='eachHamGame'>
                    <span>VIP Raffle</span>
                </div>
            </div>
            <h1>Menu</h1>
            <div className='hamGames'>
                <div className='eachHamGame'>
                    <span>Home</span>
                </div>
                <div className='eachHamGame'>
                    <span>My tickets</span>
                </div>
                <div className='eachHamGame'>
                    <span>Profile</span>
                </div>
                <div className='eachHamGame'>
                    <span>Blog</span>
                </div>
                <div className='eachHamGame'>
                    <span>Help</span>
                </div>
                <div className='eachHamGame'>
                    <span>About</span>
                </div>
                <div className='eachHamGame'>
                    <span>NFT Club</span>
                </div>
                <div className='eachHamGame'>
                    <span>Airdrops</span>
                </div>
            </div>
            <div className='sideAd1 hamAd'>
                <div className='sideAdEmoji'>
                    <img alt='' src={require('../../public/money.png')} />
                    <TelegramIcon />
                    <img alt='' src={require('../../public/money.png')} />
                </div>
                <span>Airdrops in our <br /> Telegram</span>
            </div>
        </div>
    )
}