import './style.css'
import { DiamondFund, TelegramIcon } from '../svg'

export const BetsWon = () => {
    return (
        <div className='betOptions'>
            <span className='mobileTime'>You win <img className='sandHour' alt='' src={require('../../public/grats.png')} /> Grats!</span>
            <div className='betBg'>
                <div className='myWonBet'>
                    <div className='betWinnerRel'>
                        <img alt='' src={require('../../public/navAvatar.png')} />
                        <img className='betWinnerRelCrown' alt='' src={require('../../public/crown.png')} />
                    </div>
                    <div className='myWinDetails'>
                        <p className='myWinDetailsP'>You win</p>
                        <span className='myWinDetailsSpan'><DiamondFund /> 7.93</span>
                        <span className='myWinDetailsGreySpan'>$325.79</span>
                    </div>
                </div>
                <div className='submitMyBet'>
                    <button className='stakeMyBet blueButton'><span><TelegramIcon /></span>Share your luck</button>
                    <div className='myBetBalance'>
                        <p>Next game will start in 5 sec...</p>
                    </div>
                </div>
            </div>
        </div>
    )
}