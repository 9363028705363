import './style.css'
import { TelegramIcon } from '../../component/svg'

export const Sidebar = () => {
    const menu = [
        {
            image: 'fire.png',
            title: 'TON Roulette',
            time: '00:47',
            path: '/roulettes',
        },
        {
            image: 'diamond.png',
            title: 'NFT / TON Raffle',
            path: '/raffles',
        },
        {
            image: 'ticket.png',
            title: 'Daily Lottery',
            time: '6h 47m',
            path: '/daily_lottery',
        },
        {
            image: 'lightning.png',
            title: 'Energy Games',
            path: '/energy_games',
        },
        {
            image: 'hand.png',
            title: 'Private PvP Roll',
            path: '/private_rolls',
        },
        {
            image: 'person.png',
            title: 'VIP Raffle',
            path: '/vip_raffle'
        },
    ]

    return (
        <div className='sidebar'>
            <div className='menuItem' onClick={() => window.location = '/'} style={window.location.pathname === '/' ? { background: '#272b38'} : {}}>
                <img alt='' src={require('../../public/house.png')} />
                <h2>Home</h2>
            </div>
            <div className='menuItem' onClick={() => window.location = '/my_tickets'} style={window.location.pathname === '/my_tickets' ? { background: '#272b38'} : {}}>
                <img alt='' src={require('../../public/memo.png')} />
                <h2>My Tickets</h2>
            </div>
            <h3>GAMES</h3>
            {menu.map((e, i) => (
                <div className='eachMenu' key={i} onClick={() => window.location = e?.path} style={window.location.pathname === `${e?.path}` ? { background: '#272b38'} : {}}>
                    <div className='eachMenuItems'>
                        <img alt='' src={require(`../../public/${e.image}`)} />
                        <h2>{e.title}</h2>
                    </div>
                    <span>{e.time}</span>
                </div>
            ))}
            <div className='sideAds'>
                <div className='sideAd1'>
                    <div className='sideAdEmoji'>
                        <img alt='' src={require('../../public/money.png')} />
                        <TelegramIcon />
                        <img alt='' src={require('../../public/money.png')} />
                    </div>
                    <span>Airdrops in our <br /> Telegram</span>
                </div>
                <div className='sideAd2'>
                    <div className='sideAdEmoji2'>
                        <img alt='' src={require('../../public/download.png')} />
                    </div>
                    <span>NFT PRESALE</span>
                </div>
            </div>
        </div>
    )
}