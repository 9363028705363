import './style.css'
import ReactFlagsSelect from 'react-flags-select'
import { useEffect, useState } from 'react'
import { Hamburger } from '../../component/hamburger'
import { useDispatch, useSelector } from 'react-redux'
import { LoginPopup } from '../../component/loginPopup'
import { getProfile } from '../../Redux/action/profileAction'
import { ArrowDown, CloseIcon, DiamondFund, LightningFund, NavigationLogo, TelegramIcon } from '../../component/svg'

export const Navigation = () => {
    let layout = document.querySelector('.layout')
    let layoutSide = document.querySelector('.layoutSide')
    const dispatch = useDispatch()
    const user = useSelector(st => st.ProfileReducer.user)
    const [selected, setSelected] = useState("RU")
    const [open, setOpen] = useState(false)
    const [login, setLogin] = useState(false)

    useEffect(() => {
        dispatch(getProfile())
        if (login && layout) {
            layout.style.position = 'fixed'
        } else if (!login && layout) {
            layout.style.position = 'relative '
        }
        if (open && layoutSide) {
            layoutSide.style.display = 'none'
        } else if (!open && layoutSide) {
            layoutSide.style.display = 'flex'
        }
    }, [login, layout, layoutSide, open, dispatch])

    return (
        <div className='navigation'>
            <LoginPopup
                login={login}
                setLogin={setLogin}
            />
            {open && <Hamburger user={user} />}
            <div className='navLogo' onClick={() => window.location = '/'}>
                <NavigationLogo />
            </div>
            <div className='navbar'>
                <div className='navLeftPart'>
                    <span>HELP</span>
                    <span>BLOG</span>
                    <span>AIRDROPS  <img alt='' src={require('../../public/download.png')} /></span>
                    <span>NFT CLUB</span>
                </div>
                <div className='navRightPart'>
                    <ReactFlagsSelect
                        selected={selected}
                        onSelect={(code) => setSelected(code)}
                        countries={['US', 'RU', 'PT']}
                        showOptionLabel={false}
                        showSelectedLabel={false}
                    />
                    {Object.keys(user).length > 0
                        ? <>
                            <div className='navFunds'>
                                <div className='eachFund'>
                                    <DiamondFund />
                                    <span>{user?.wallet_ton_balance ? Number(user?.wallet_ton_balance)?.toFixed(2) : '0.00'}</span>
                                </div>
                                <div className='eachFund'>
                                    <LightningFund />
                                    <span>34</span>
                                </div>
                                <button>+ Add Funds</button>
                            </div>
                            <div className='navDropdown'>
                                <img alt='' src={user?.photo_url} onClick={() => window.location = '/profile'} />
                            </div>
                        </>
                        : <div className='loginViaTelegram' onClick={() => setLogin(true)}>
                            <TelegramIcon />
                            <span>Log in</span>
                        </div>
                    }
                </div>
                <div className='mobileNavRightPart'>
                    {Object.keys(user).length > 0
                        ? <>
                            <div className='eachFund'>
                                <DiamondFund />
                                <span>10</span>
                            </div>
                            <div className='eachFund'>
                                <LightningFund />
                                <span>34</span>
                            </div>

                        </>
                        : <div className='loginViaTelegram' onClick={() => setLogin(true)}>
                            <TelegramIcon />
                            <span>Log in</span>
                        </div>
                    }
                    <div className='navMenu' onClick={() => setOpen(!open)}>
                        {open
                            ? <>
                                <span>CLOSE</span>
                                <CloseIcon />
                            </>
                            : <>
                                <span>MENU</span>
                                <ArrowDown />
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}