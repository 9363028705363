import './style.css'
import { DiamondFund } from '../svg'

export const BetsRolling = () => {
    return (
        <div className='betOptions'>
            <span className='mobileTime'>Waiting roll results...</span>
            <div className='betBg'>
                <div className='myBet'>
                    <span>Your bet</span>
                    <span>$270.39</span>
                </div>
                <span className='winChance'><DiamondFund />107 with <span className='winPercent'>76%</span>win chance</span>
                <div className='crossedFingers'>
                    <img alt='' src={require('../../public/diamond.png')} />
                    <img className='crossedImg' alt='' src={require('../../public/crossedFingers.png')} />
                    <img alt='' src={require('../../public/diamond.png')} />
                </div>
                <div className='submitMyBet'>
                    <div className='myBetBalance'>
                        <p>Wait a moment while rolling...</p>
                    </div>
                </div>
            </div>
        </div>
    )
}