import './style.css'
import TelegramLoginButton from 'react-telegram-login'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { CloseIcon, GreyGreaterThan } from '../svg'
import { Login } from '../../Redux/action/loginAction'

export const LoginPopup = ({ login, setLogin }) => {
    const dispatch = useDispatch()
    const handleTelegramResponse = res => {
        console.log('tg res --->>>', res);
        // dispatch(Login({
        //     id: res?.id,
        //     first_name: res?.first_name,
        //     last_name: res?.last_name,
        //     username: res?.username,
        //     photo_url: res?.photo_url,
        //     auth_date: res?.auth_date,
        //     hash: res?.hash,
        // }))
    }

    useEffect(() => {
        dispatch(Login({
            auth_date: 1684737697,
            first_name: "Mane",
            hash: "831c3d202d569447f0fc2c2db5d92d324c658ecb6a43a644f02968cfa832dbf5",
            id: 1045983875,
            last_name: "Gevorgyan",
            photo_url: "https://t.me/i/userpic/320/GK6d819LLcIGauN2a_7Szxj5E1yWtvW53UUBJ0Qh9ow.jpg",
            username: "gevorgyanmane",
        }))
    }, [dispatch])

    return (
        <div className={login ? 'active' : 'inactive'}>
            <div className='loginPopup'>
                <div className='closeLoginPopup' onClick={() => setLogin(false)}>
                    <CloseIcon />
                </div>
                <h1>Log in to start earning <br /> TONs</h1>
                <div className='mainLogPop'>
                    <img alt='' src={require('../../public/loginQR.png')} />
                    <div className='mainLogPopRight'>
                        <div className='logPopIcons'>
                            <img alt='' src={require('../../public/Backhand.png')} />
                            <img alt='' src={require('../../public/phone.png')} />
                        </div>
                        <p>Scan this QR code and <br /> press Start' in <br /> Telegram bot</p>
                    </div>
                </div>
                <div className='downloadTg'>
                    <span>Download Telegram <GreyGreaterThan /> </span>
                </div>
                <div className='goToTg'>
                    <TelegramLoginButton dataOnauth={handleTelegramResponse} botName="staging_tonlottery_com_bot" />
                </div>
                <div className='downloadTg'>
                    <span>By clicking this button you agree to our Terms and <br /> Conditions and Privacy Policy</span>
                </div>
            </div>
        </div>
    )
}