import './style.css'
import QRCode from 'react-qr-code'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { BigDiamond, BigLightning, GreaterThan } from '../../component/svg'

export const Profile = () => {
    const [email, setEmail] = useState('')
    const [showName, setShowName] = useState(true)
    const user = useSelector(st => st.ProfileReducer.user)

    function copy(id) {
        const walletAddress = document.getElementById(id)
        navigator.clipboard.writeText(walletAddress.value)
    }

    return (
        <div className='profile eachPage'>
            <div className='profileTop'>
                <div className='profTopLeft'>
                    <img alt='' src={user?.photo_url} />
                    <span>@{user?.username}</span>
                </div>
                <div className='profTopRight'>
                    <div className='profTopFunds'>
                        <div className='profTopFundIcon'>
                            <BigDiamond />
                            <span>{user?.wallet_ton_balance ? Number(user?.wallet_ton_balance)?.toFixed(2) : '0.00'}</span>
                        </div>
                        <span>$325.79</span>
                    </div>
                    <div className='profTopFunds'>
                        <div className='profTopFundIcon'>
                            <BigLightning />
                            <span>32</span>
                        </div>
                        <span>Energy points</span>
                    </div>
                </div>
            </div>
            <div className='profileCards'>
                <div className='profLeftCard'>
                    <h1>Send TON</h1>
                    <div className='mainLogPop profQR'>
                        <QRCode value={Object.keys(user)?.length ? user.qr_code_link : ""} size={256} viewBox={`0 0 256 256`} />
                        <div className='mainLogPopRight'>
                            <div className='logPopIcons'>
                                <img alt='' src={require('../../public/Backhand.png')} />
                                <img alt='' src={require('../../public/phone.png')} />
                            </div>
                            <p>Scan this QR code and <br /> press “Start” in <br /> Telegram bot</p>
                        </div>
                    </div>
                    <h2>Direct links</h2>
                    <div className='profOpenIn'>
                        <div className='eachOpenIn'>
                            <span>Open in Tonkeeper <GreaterThan /> </span>
                        </div>
                        <div className='eachOpenIn'>
                            <span>Open in Tonhub  <GreaterThan /> </span>
                        </div>
                    </div>
                    <h2 className='manual'>Manual way</h2>
                    <span>1. Copy and paste this address</span>
                    <div className='profInputs'>
                        <input value={user?.wallet_address?.length ? user?.wallet_address : ''} disabled={true} id='walletAddress' />
                        <button onClick={() => copy('walletAddress')}>Copy</button>
                    </div>
                    <span>2. Copy and paste your unique ID in the comment section (do not forget it!)</span>
                    <div className='profInputs'>
                        <input value={user?.id?.length ? user?.id : ''} disabled={true} id='userId' />
                        <button onClick={() => copy('userId')}>Copy</button>
                    </div>
                    <span>3. Enter the number of TONs to send and finish the transfer</span>
                    <h4>🤠  Please, be attentive and check twice if you enter data manually</h4>
                </div>
                <div className='profRightCards'>
                    <div className='profRightTopCard'>
                        <div className='buyTon'>
                            <h1>Buy TON</h1>
                            <span>Here are some ways how you can buy TON coins:</span>
                            <p>TON is a blockchain ecosystem created from the ground up to make digital life easier.
                                If you’re an NFT artist or a collector, TON is the deal tool for you thanks to its
                                high performance and low transaction fees. And it’s eco-friendly.
                            </p>
                        </div>
                        <div className='buyTonLinks'>
                            <div className='eachTonLink'>
                                <span>Links here <GreaterThan /> </span>
                            </div>
                            <div className='eachTonLink'>
                                <span>Links here <GreaterThan /> </span>
                            </div>
                            <div className='eachTonLink'>
                                <span>Links here <GreaterThan /> </span>
                            </div>
                            <div className='eachTonLink'>
                                <span>Links here <GreaterThan /> </span>
                            </div>
                            <div className='eachTonLink'>
                                <span>Links here <GreaterThan /> </span>
                            </div>
                        </div>
                    </div>
                    <div className='profRightBottomCard'>
                        <div className='showName'>
                            <h3>Show my real name</h3>
                            <div className='showOrHide'>
                                <div className='eachShow' style={showName ? { border: '0.5px solid #1F9FFD' } : {}} onClick={() => {
                                    if (!showName) {
                                        setShowName(!showName)
                                    }
                                }}>
                                    <span style={showName ? { color: '#1F9FFD' } : {}}>Show</span>
                                </div>
                                <div className='eachShow' style={showName ? {} : { border: '0.5px solid #1F9FFD' }} onClick={() => {
                                    if (showName) {
                                        setShowName(!showName)
                                    }
                                }}>
                                    <span style={showName ? {} : { color: '#1F9FFD' }}>Hide</span>
                                </div>
                            </div>
                        </div>
                        <div className='eachTonLink bottomLink'>
                            <span>Transfer funds <GreaterThan /> </span>
                        </div>
                        <h3>Don’t miss airdrops and closed raffles!</h3>
                        <span>We will notify you when there’ll be a chance for you to earn some NFT of TON prizes</span>
                        <div className='profInputs'>
                            <input placeholder='Your email' value={email} onChange={(e) => setEmail(e.target.value)} />
                            <button>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}