import store from "../store/store"

export const ProfileReducer = (state = store, action) => {
    let temp = { ...state }
    switch (action.type) {
        case 'profile':
            if (action.payload.status === 200) {
                temp.user = action.payload.data
            }
            break;
        case 'noCookie':
            temp.user = {};
            break;
        default:
            return temp;
    }
    return temp;
}