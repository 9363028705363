import { CloseIcon, GreaterThan } from '../svg'
import './style.css'

export const BuyTicketPopupQr = ({ open, setOpen }) => {
    return (
        <div className={open ? 'active' : 'inactive'}>
            <div className='loginPopup buyQrPopup'>
                <div className='closeLoginPopup' onClick={() => setOpen(false)}>
                    <CloseIcon />
                </div>
                <h1>Scan QR</h1>
                <div className='buyQr'>
                    <img alt='' src={require('../../public/buyQr.png')} />
                </div>
                <span>or pay by links</span>
                <div className='profOpenIn profOpenInQr'>
                    <div className='eachOpenIn'>
                        <span>Tonkeeper <GreaterThan /> </span>
                    </div>
                    <div className='eachOpenIn'>
                        <span>Tonhub  <GreaterThan /> </span>
                    </div>
                </div>
                <span>We are checking your payment. It may take some time</span>
            </div>
        </div>
    )
}