import { useState } from 'react'
import { BlueGreaterThan, Cancel } from '../svg'


export const NoBets = () => {
    const [betStake, setBetStake] = useState(0.1)

    return (
        <div className='betOptions'>
            <span>Start game with your stake <img className='sandHour' alt='' src={require('../../public/manHat.png')} /></span>
            <div className='betBg'>
                <div className='myBet'>
                    <span>Your bet</span>
                    <span>$2.27</span>
                </div>
                <span className='winChance'>Make the first move!</span>
                <div className='upMyBet'>
                    <div className='inputBet'>
                        <div className='myBetInput'>
                            <input placeholder='Your value' value={betStake} onChange={(e) => setBetStake(e.target.value)} />
                            <div className='cancelBet' onClick={() => setBetStake('')} >
                                <Cancel />
                            </div>
                        </div>
                        <div className='upBet' onClick={() => setBetStake(+betStake + 0.1)}>
                            <span>+0.1</span>
                        </div>
                        <div className='upBet' onClick={() => setBetStake(+betStake + 1)}>
                            <span>+1</span>
                        </div>
                        <div className='upBet' onClick={() => setBetStake(+betStake + 5)}>
                            <span>+5</span>
                        </div>
                    </div>
                </div>
                <div className='submitMyBet'>
                    <button className='stakeMyBet greenButton'>Stake <span><img alt='' src={require('../../public/fire.png')} /></span></button>
                    <div className='myBetBalance'>
                        <p>Your balance: 15.44 TON</p>
                        <span>Deposit <BlueGreaterThan /></span>
                    </div>
                </div>
            </div>
        </div>
    )
}