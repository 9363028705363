import './style.css'

export const AdminSidebar = () => {
    return (
        <div className='sidebar adminSidebar'>
            <div className='menuItem' style={window.location.pathname === '/' ? { background: '#272b38' } : {}}>
                <h2>PvP Roulette</h2>
            </div>
            <div className='menuItem' style={window.location.pathname === '/my_tickets' ? { background: '#272b38' } : {}}>
                <h2>Raffles</h2>
            </div>
            <div className='menuItem' style={window.location.pathname === '/my_tickets' ? { background: '#272b38' } : {}}>
                <h2>Management</h2>
            </div>
        </div>
    )
}