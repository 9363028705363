import './style.css'
import { useState } from 'react'
import ReactPaginate from 'react-paginate'

export const GamesArchive = () => {
    const [pageCount, setPageCount] = useState(10)
    const [header, setHeader] = useState({
        title: 'PvP Roll',
        value: [
            'Date',
            'Number',
            'Stake pool',
            'Players',
            'Winner',
            'Hash'
        ],
        data: [
            {
                date: '27.01.2023 08:52:30',
                number: '234567',
                stakePool: '13.101 TON',
                players: 'Animals Red List',
                winner: 'navAvatar.png',
                hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
            },
            {
                date: '27.01.2023 08:52:30',
                number: '234567',
                stakePool: '13.101 TON',
                players: 'Animals Red List',
                winner: 'navAvatar.png',
                hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
            },
            {
                date: '27.01.2023 08:52:30',
                number: '234567',
                stakePool: '13.101 TON',
                players: 'Animals Red List',
                winner: 'navAvatar.png',
                hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
            },
            {
                date: '27.01.2023 08:52:30',
                number: '234567',
                stakePool: '13.101 TON',
                players: 'Animals Red List',
                winner: 'navAvatar.png',
                hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
            },
            {
                date: '27.01.2023 08:52:30',
                number: '234567',
                stakePool: '13.101 TON',
                players: 'Animals Red List',
                winner: 'navAvatar.png',
                hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
            },
            {
                date: '27.01.2023 08:52:30',
                number: '234567',
                stakePool: '13.101 TON',
                players: 'Animals Red List',
                winner: 'navAvatar.png',
                hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
            },
            {
                date: '27.01.2023 08:52:30',
                number: '234567',
                stakePool: '13.101 TON',
                players: 'Animals Red List',
                winner: 'navAvatar.png',
                hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
            },
            {
                date: '27.01.2023 08:52:30',
                number: '234567',
                stakePool: '13.101 TON',
                players: 'Animals Red List',
                winner: 'navAvatar.png',
                hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
            },
            {
                date: '27.01.2023 08:52:30',
                number: '234567',
                stakePool: '13.101 TON',
                players: 'Animals Red List',
                winner: 'navAvatar.png',
                hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
            },
            {
                date: '27.01.2023 08:52:30',
                number: '234567',
                stakePool: '13.101 TON',
                players: 'Animals Red List',
                winner: 'navAvatar.png',
                hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
            },
            {
                date: '27.01.2023 08:52:30',
                number: '234567',
                stakePool: '13.101 TON',
                players: 'Animals Red List',
                winner: 'navAvatar.png',
                hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
            },
            {
                date: '27.01.2023 08:52:30',
                number: '234567',
                stakePool: '13.101 TON',
                players: 'Animals Red List',
                winner: 'navAvatar.png',
                hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
            },
            {
                date: '27.01.2023 08:52:30',
                number: '234567',
                stakePool: '13.101 TON',
                players: 'Animals Red List',
                winner: 'navAvatar.png',
                hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
            },
        ]
    })
    const handelChage = (e) => {
        if (e === 'NFT Raffle') {
            setHeader({
                title: 'NFT Raffle',
                value: [
                    'Date',
                    'Number',
                    'NFT Price',
                    'Collection',
                    'Winner',
                    'Hash'
                ],
                data: [
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: '3',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: '3',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: '3',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: '3',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: '3',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: '3',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: '3',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: '3',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: '3',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: '3',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: '3',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: '3',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: '3',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                ]
            })
        }
        else if (e === 'PvP Roll')
            setHeader({
                title: 'PvP Roll',
                value: [
                    'Date',
                    'Number',
                    'Stake pool',
                    'Players',
                    'Winner',
                    'Hash'
                ],
                data: [
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: 'Animals Red List',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: 'Animals Red List',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: 'Animals Red List',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: 'Animals Red List',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: 'Animals Red List',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: 'Animals Red List',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: 'Animals Red List',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: 'Animals Red List',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: 'Animals Red List',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: 'Animals Red List',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: 'Animals Red List',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: 'Animals Red List',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                    {
                        date: '27.01.2023 08:52:30',
                        number: '234567',
                        stakePool: '13.101 TON',
                        players: 'Animals Red List',
                        winner: 'navAvatar.png',
                        hash: 'jdJMyTJSwB8iSbY0U5RDF-0CHjscVnfkPI0dUWjvevY=',
                    },
                ]
            })
    }

    return (
        <div className='gamesArchive eachPage'>
            <div className='backToRaffleList backFromArchive'>
                <span onClick={() => window.location = '/roulettes'}>&lt; Back to raffle list</span>
            </div>
            <h1>Games archive</h1>
            <select onChange={(e) => handelChage(e.target.value)}>
                <option >PvP Roll</option>
                <option>NFT Raffle</option>
            </select>
            <div className='tableDiv'>
                <table >
                    <thead>

                        <tr className='tableHead' >
                            <th className='tableMarginTh'></th>
                            {header.value.map((elm, i) => (
                                <th className='tabelTh' key={i}>{elm}</th>
                            ))

                            }
                            <th className='tableMarginTh'></th>
                        </tr>
                        <tr className='tabelMarginTh' />
                    </thead>
                    <tbody>
                        {header.data?.length > 0 && header.data.map((e, i) => (
                            <tr key={i} className='tableContent'>
                                <td className='tableTr'></td>
                                <td className='tableTr'>{e?.date}</td>
                                <td className='tableTr'>{e?.number}</td>
                                <td className='tableTr'>{e?.stakePool}</td>
                                <td className='tableTr'>{e?.players}</td>
                                <td className='tableTr'><img alt='' src={require(`../../public/${e?.winner}`)} /></td>
                                <td className='tableTr'>{e?.hash}</td>
                                <td className='tableTr'></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div>
                <ReactPaginate
                    // onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="<"
                    nextLabel=">"
                    breakLabel="..."
                    containerClassName="pagination"
                    activeClassName="actives"
                    renderOnZeroPageCount={null}
                />
            </div>
        </div>
    )
} 