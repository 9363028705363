import axios from "axios"

export const StartGame = (data) => {
    return async (dispatch) => {
        await axios.post(`https://staging.tonlottery.com/roulette/start?bidamount=1`, {}, {
            headers: {
                mycookie: '132abcc062d10463a6a9a0b8764d7cab18fda6cb9aadf6271ef27b05dc175e52',
            },
        })
            .then(
                res => dispatch({
                    type: 'startGame',
                    payload: res
                })
            )
            .catch(error => console.log(error.message))
    }
}
