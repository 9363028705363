import './style.css'
import ReactPaginate from 'react-paginate'
import { useState } from 'react'
import { RafflePromo } from '../../component/rafflePromo'
import { TicketFilter } from '../../component/ticketFilter'
import { MyWinningTickets } from '../../component/myWinningTickets'
import { MyUpcomingTickets } from '../../component/myUpcomingTickets'
import { MyFinishedTickets } from '../../component/myFinishedTickets'


export const MyTickets = () => {
    const [active, setActive] = useState(0)
    const [pageCount, setPageCount] = useState(10)
    return (
        <div className='myTickets eachPage'>
            <RafflePromo />
            <div className='myTicketsTitle'>
                <h1>My tickets</h1>
                <span>Archive &#62; </span>
            </div>
            <TicketFilter active={active} setActive={setActive} />
            {active === 0 && <MyUpcomingTickets />}
            {active === 1 && <MyWinningTickets />}
            {active === 2 && <MyFinishedTickets />}
            <ReactPaginate
                // onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="<"
                nextLabel=">"
                breakLabel="..."
                containerClassName="pagination"
                activeClassName="actives"
                renderOnZeroPageCount={null}
            />
        </div>
    )
}