import { DiamondFund } from '../svg'

export const BetsNoPlayed = () => {
    return (
        <div className='betOptions'>
            <span className='mobileTime'>Round finished</span>
            <div className='betBg'>
                <div className='myWonBet'>
                    <div className='betWinnerRel'>
                        <img alt='' src={require('../../public/navAvatar.png')} />
                        <img className='betWinnerRelCrown' alt='' src={require('../../public/crown.png')} />
                    </div>
                    <div className='myWinDetails'>
                        <p className='totalnDetailsP'>Total win</p>
                        <span className='myWinDetailsSpan'><DiamondFund /> 7.93</span>
                        <span className='myWinDetailsGreySpan'>$325.79</span>
                    </div>
                </div>
                <div className='submitMyBet'>
                    <div className='myBetBalance'>
                        <p>Next game will start in 5 sec...</p>
                    </div>
                </div>
            </div>
        </div>
    )
}