import './style.css'
import { RouletteDiamond, TelegramIcon } from '../svg'

export const MyWinningTickets = () => {
    const tickets = [
        {
            image: '1.png',
            title: 'G-BOT #4390',
            description: 'Emerald eyed G-BOT with a Golden Outer Body, Plastic Inner Body and Plastic Elements on Oxley Background. Featuring Origin Full Progenitor Set',
            cryptoAmount: '45',
            soldAmount: '17 / 45',
            id: '1',
            myTicketCount: '4',
            gameNumber: '342488',
            finishDate: '18:30, 23 Jan',
            youPaid: '4.4 TON',
            combinations: '65 76 868; 86 87 666',
            avatar: 'navAvatar.png'
        },
        {
            image: '1.png',
            title: 'G-BOT #4390',
            description: 'Emerald eyed G-BOT with a Golden Outer Body, Plastic Inner Body and Plastic Elements on Oxley Background. Featuring Origin Full Progenitor Set',
            cryptoAmount: '45',
            soldAmount: '17 / 45',
            id: '1',
            myTicketCount: '0',
            gameNumber: '342488',
            finishDate: '18:30, 23 Jan',
            youPaid: '4.4 TON',
            combinations: '65 76 868; 86 87 666',
            avatar: 'navAvatar.png'
        },
        {
            image: 'Group161.png',
            title: 'TON COIN Raffle',
            description: 'Emerald eyed G-BOT with a Golden Outer Body, Plastic Inner Body and Plastic Elements on Oxley Background. Featuring Origin Full Progenitor Set',
            cryptoAmount: '45',
            soldAmount: '17 / 45',
            id: '3',
            myTicketCount: '5',
            gameNumber: '342488',
            finishDate: '18:30, 23 Jan',
            youPaid: '4.4 TON',
            combinations: '65 76 868; 86 87 666',
            avatar: 'navAvatar.png'
        },
    ]
    return (
        <div className='myWinningTickts'>
            {tickets.length > 0 && tickets.map((e, i) => (
                <div className='eachWinningTicket' key={i}>
                    <div className='eachUpcomingTicket eachWin'>
                        <div className='mobUp' key={i}>
                            <img className='upcomingimg' alt='' src={require(`../../public/${e.image}`)} />
                            <div className='upcomingRight'>
                                <h2>{e?.title}</h2>
                                <p>{e?.description}</p>
                                <div className='upcomingCryptos'>
                                    <div className='eachUpcomingCrypto'>
                                        <div className='cryptoIcon upcomingCrypto'>
                                            <RouletteDiamond />
                                            <span>{e.cryptoAmount}</span>
                                        </div>
                                        <p>NFT price</p>
                                    </div>
                                    <div className='eachUpcomingCrypto'>
                                        <div className='cryptoIcon upcomingCrypto'>
                                            <span>{e.soldAmount}</span>
                                        </div>
                                        <p>Tickets sold</p>
                                    </div>
                                    <div className='eachUpcomingCrypto'>
                                        <div className='cryptoIcon upcomingCrypto'>
                                            <span>{e.myTicketCount}</span>
                                        </div>
                                        <p>Your tickets</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='upcomingCryptos mobileUpcomingCryptos' style={{ margin: '25px 0' }}>
                        <div className='eachUpcomingCrypto'>
                            <div className='cryptoIcon upcomingCrypto'>
                                <RouletteDiamond />
                                <span>{e.cryptoAmount}</span>
                            </div>
                            <p>NFT price</p>
                        </div>
                        <div className='eachUpcomingCrypto'>
                            <div className='cryptoIcon upcomingCrypto'>
                                <span>{e.soldAmount}</span>
                            </div>
                            <p>Tickets sold</p>
                        </div>
                        <div className='eachUpcomingCrypto'>
                            <div className='cryptoIcon upcomingCrypto'>
                                <span>{e.myTicketCount}</span>
                            </div>
                            <p>Your tickets</p>
                        </div>
                    </div>
                    <div className='eachWinBottom'>
                        <div className='winnerWin'>
                            <img className='winnerWinAvatar' alt='' src={require(`../../public/${e?.avatar}`)} />
                            <img className='winnerCrown' alt='' src={require('../../public/crown.png')} />
                        </div>
                        <div className='eachWinDetails'>
                            <div className='eachWinDetail1'>
                                <div className='eachDet'>
                                    <p>Game number:</p>
                                    <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>{e?.gameNumber}</span>
                                </div>
                                <div className='eachDet'>
                                    <p>Raffle finished:</p>
                                    <span>{e?.finishDate}</span>
                                </div>
                            </div>
                            <div className='eachWinDetail1'>
                                <div className='eachDet'>
                                    <p>You paid:</p>
                                    <span>{e?.youPaid}</span>
                                </div>
                                <div className='eachDet'>
                                    <p>Combinations:</p>
                                    <span>{e?.combinations}</span>
                                </div>
                            </div>
                        </div>
                        <div className='shareMyWin'>
                            <TelegramIcon />
                            <span>Share win</span>
                        </div>
                    </div>
                    <div className='shareMyWin mobileShareMyWin'>
                        <TelegramIcon />
                        <span>Share win</span>
                    </div>
                </div>
            ))}
        </div>
    )
}