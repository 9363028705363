import './style.css'
import { useState } from 'react'
import { GreaterThan } from '../../component/svg'
import { BuyTicketPopup } from '../buyTicketPopup'
import { BuyTicketPopupLoading } from '../buyTicketPopupLoading'
import { BuyTicketPopupQr } from '../buyTicketPopupQr'
import { TransferTonPopup } from '../transferTonPopup'

export const RouletteTickets = () => {
    const tickets = [
        {
            image: 'chart.png',
            id: '1',
        },
        {
            image: 'chart.png',
            id: '2',
        },
        {
            image: 'chart.png',
            id: '3',
        },
    ]
    const [ticketId, setTicketId] = useState()
    const [buyTicket, setBuyTicket] = useState(false)
    const [openLoading, setOpenLoading] = useState(false)
    const [openQr, setOpenQr] = useState(false)
    const [openTransfer, setOpenTransfer] = useState(false)

    return (
        <div className='rouletteTickets'>
            <BuyTicketPopup open={buyTicket} setOpen={setBuyTicket} id={ticketId} setOpenLoading={setOpenLoading} />
            <BuyTicketPopupLoading open={openLoading} setOpen={setOpenLoading} setOpenQr={setOpenQr} />
            <BuyTicketPopupQr open={openQr} setOpen={setOpenQr} />
            <TransferTonPopup open={openTransfer} setOpen={setOpenTransfer} /> 
            {tickets.length > 0 && tickets.map((e, i) => (
                <div className='eachTicket' key={i}>
                    <img alt='' src={require(`../../public/${e.image}`)} />
                    <span onClick={() => {
                        setBuyTicket(true)
                        setTicketId(e?.id)
                    }}>Buy tickets <GreaterThan /></span>
                </div>
            ))}
        </div>
    )
}