import axios from "axios"

export const getProfile = () => {
    if (localStorage.getItem('Cookie')) {
        return async (dispatch) => {
            await axios.get('https://backend.tonlottery.com/users/me', {
                headers: {
                    'Cookie': localStorage.getItem('Cookie')
                }
            })
                .then(res => dispatch({
                    type: 'profile',
                    payload: res
                }))
                .catch(error =>
                    console.log(error)
                )
        }
    } else {
        console.log('No Cookie')
        return async (dispatch) => {
            dispatch({
                type: 'noCookie',
            })
        }
    }
}