import { Home } from "./container/home"
import { Layout } from "./container/layout"
import { Profile } from "./container/profile"
import { Roulettes } from "./container/roulettes"
import { MyTickets } from "./container/myTickets"
import { SingleRaffle } from "./container/singleRaffle"
import { GamesArchive } from "./container/gamesArchive"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { RouletteBets } from "./container/rouletteBets"
import { AdminLayout } from "./component/adminLayout"
import { AdminRaffleList } from "./component/adminRaffleList"

export const MyRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/roulettes" element={<Roulettes />} />
                    <Route path="/my_tickets" element={<MyTickets />} />
                    <Route path="/raffle/:id" element={<SingleRaffle />} />
                    <Route path="/games_archive" element={<GamesArchive />} />
                    <Route path="/roulette_bets" element={<RouletteBets />} />
                </Route>
                <Route path='/admin' element={<AdminLayout />} >
                    <Route path="/admin" element={<AdminRaffleList />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}