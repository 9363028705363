import { Outlet } from 'react-router-dom'
import { AdminSidebar } from '../adminSidebar'

export const AdminLayout = () => {
    return (
        <div className='layoutSide'>
            <AdminSidebar />
            <div className='outlet'>
                <Outlet />
            </div>
        </div>
    )
} 