import './style.css'
import { BetPlayers } from '../../component/betPlayers'
import { RafflePromo } from '../../component/rafflePromo'
import { PreviousGames } from '../../component/prevoiusGames'
import { HowToPlay } from '../../component/howToPlay'
import HowToPlayAccordion from '../../component/howToPlayAccordion'

export const RouletteBets = () => {
    return (
        <div className='rouletteBets eachPage'>
            <RafflePromo />
            <h1>TON Roulette</h1>
            <BetPlayers />
            <PreviousGames />
            <HowToPlay />
            <HowToPlayAccordion />
        </div>
    )
}   