import './style.css'

export const HowToPlay = () => {
    return (
        <div className='howToPlay'>
            <h2>How to play</h2>
            <ol>
                <li>To participate in NFT Raffle, you need to have a TON wallet and some coins.</li>
                <li>Each NFT Raffle has a limited number of tickets available for purchase.</li>
                <li>The more tickets you buy, the greater your chances of winning the NFT.</li>
                <li>Once all tickets are sold out, the winner is selected randomly through a smart-contract on the TON blockchain.</li>
                <li>The winner will receive the NFT prize, which will be sent directly to their connected TON wallet.</li>
                <li>The game is based on TON smart-contract, which ensures fair play and transparency.</li>
                <li>Please gamble responsibly and only buy as many tickets as you can afford to lose.</li>
            </ol>
        </div>
    )
}