import './style.css'
import { useEffect, useState } from 'react'
import { DiamondFund } from '../../component/svg'

export const PreviousGames = () => {
    const previousGames = [
        {
            iWon: true,
            myTotalWin: '137',
            gameCode: 'EQCNaAP9...8MlgrIcO',
            iPlayedWith: [
                {
                    image: 'navAvatar.png',
                    winner: true,
                    diamondFund: '107',
                    dollarFund: '$270.39'
                },
                {
                    image: 'navAvatar.png',
                    winner: false,
                    diamondFund: '107',
                    dollarFund: '$270.39'
                },
                {
                    image: 'navAvatar.png',
                    winner: false,
                    diamondFund: '107',
                    dollarFund: '$270.39'
                },
            ],
        },
        {
            iWon: false,
            myTotalWin: '137',
            gameCode: 'EQCNaAP9...8MlgrIcO',
            iPlayedWith: [
                {
                    image: 'navAvatar.png',
                    winner: true,
                    diamondFund: '107',
                    dollarFund: '$270.39'
                },
                {
                    image: 'navAvatar.png',
                    winner: false,
                    diamondFund: '107',
                    dollarFund: '$270.39'
                },
                {
                    image: 'navAvatar.png',
                    winner: false,
                    diamondFund: '107',
                    dollarFund: '$270.39'
                },
                {
                    image: 'navAvatar.png',
                    winner: false,
                    diamondFund: '107',
                    dollarFund: '$270.39'
                },
                {
                    image: 'navAvatar.png',
                    winner: false,
                    diamondFund: '107',
                    dollarFund: '$270.39'
                },
            ],
        },
        {
            iWon: false,
            myTotalWin: '137',
            gameCode: 'EQCNaAP9...8MlgrIcO',
            iPlayedWith: [
                {
                    image: 'navAvatar.png',
                    winner: true,
                    diamondFund: '107',
                    dollarFund: '$270.39'
                },
                {
                    image: 'navAvatar.png',
                    winner: false,
                    diamondFund: '107',
                    dollarFund: '$270.39'
                },
                {
                    image: 'navAvatar.png',
                    winner: false,
                    diamondFund: '107',
                    dollarFund: '$270.39'
                },
                {
                    image: 'navAvatar.png',
                    winner: false,
                    diamondFund: '107',
                    dollarFund: '$270.39'
                },
                {
                    image: 'navAvatar.png',
                    winner: false,
                    diamondFund: '107',
                    dollarFund: '$270.39'
                },
            ],
        },
    ]
    // function seeMore(i) {
    //     let index = previousGames.findIndex(e => e === i)
    //     if (index === 0) {
    //         show.splice(index, 1);
    //     } else {
    //         show.push(i)
    //     };
    //     setShow(oldArray => [...oldArray])
    //     document.querySelector('.prevGameCenter').style.flexWrap = 'wrap'
    // }
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    // const [show, setShow] = useState([])
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const mobile = windowSize.innerWidth <= 1024;

    return (
        <div className='previousGames'>
            <h1>Previous Games</h1>
            <h1 className='pastGames'>Past games</h1>
            <div className='allPrevGames'>
                {previousGames.length > 0 && previousGames.map((e, i) => (
                    <div className='eachPrevGame' key={i}>
                        <div className='mobPrevGame'>
                            <div className='prevGameLeft'>
                                {e?.iWon
                                    ? <span className='iWin'>You win</span>
                                    : <span className='totalWin'>Total win</span>
                                }
                                <span><DiamondFund /> {e.myTotalWin}</span>
                            </div>
                            <div className='prevGameCenter'>
                                {mobile
                                    ? <div className='eachBetPlayerWeb eachPrevGameWinner'>
                                        <img alt='' src={require(`../../public/${e?.iPlayedWith.find(e => e.winner)?.image}`)} />
                                        <div className='webBetChance'>
                                            <span className='eachPlayerChanceWeb'><DiamondFund /> {e?.iPlayedWith.find(e => e.winner)?.diamondFund}</span>
                                            <span className='webPrice'>{e?.iPlayedWith.find(e => e.winner)?.dollarFund}</span>
                                        </div>
                                        {e?.iPlayedWith.find(e => e.winner).winner && <img className='prevGameWinner' alt='' src={require('../../public/crown.png')} />}
                                    </div>
                                    : e?.iPlayedWith?.length > 0 && e?.iPlayedWith?.map((elm, i) => (
                                        <div className='eachBetPlayerWeb eachPrevGameWinner' key={i}>
                                            <img alt='' src={require(`../../public/${elm?.image}`)} />
                                            <div className='webBetChance'>
                                                <span className='eachPlayerChanceWeb'><DiamondFund /> {elm?.diamondFund}</span>
                                                <span className='webPrice'>{elm?.dollarFund}</span>
                                            </div>
                                            {elm.winner && <img className='prevGameWinner' alt='' src={require('../../public/crown.png')} />}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='prevGameRight'>
                            <span>{e?.gameCode}</span>
                        </div>
                    </div>
                ))}
            </div>
            <div className='seeMorePreviousGames transparentButton'>
                <span>See more</span>
            </div>
        </div>
    )
}