import { combineReducers } from 'redux'
import { LoginReducer } from './loginReducer'
import { ProfileReducer } from './profileReducer'
import { GameReducer } from './gameReducer'

export default combineReducers({
    LoginReducer,
    ProfileReducer,
    GameReducer
})
