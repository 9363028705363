import './style.css'

export const HomeGuide = () => {
    const guides = [
        {
            title: 'Join our Telegram',
            text: 'Whitelisting, airdrops and closed events in our @tonlotterychannel',
            image: 'ASAP.png',
        },
        {
            title: 'How to earn TON',
            text: 'Ultimate guide for Play-2-Earn strategies in blockchain world',
            image: 'ASAP2.png',
        },
    ]

    return (
        <div className='guides'>
            {guides.length > 0 && guides.map((e, i) => (
                <div className='eachGuide' key={i}>
                    <div className='guideLeftPart'>
                        <h2>{e.title}</h2>
                        <h3>{e.text}</h3>
                    </div>
                    <img alt='' src={require(`../../public/${e.image}`)} />
                </div>
            ))}
        </div>
    )
}