import './style.css'
import { useState } from 'react'
import { CloseIcon } from '../svg'

export const BuyTicketPopup = ({ open, setOpen, id, setOpenLoading }) => {
    const [tonCount, setTonCount] = useState(1)

    function handlePriceChange(change) {
        if (change === 'plus') {
            tonCount !== 99999 && setTonCount(tonCount + 1)
        } else {
            tonCount !== 1 && setTonCount(tonCount - 1)
        }
    }

    return (
        <div className={open ? 'active' : 'inactive'}>
            <div className='loginPopup buyTicketPopup'>
                <div className='closeLoginPopup' onClick={() => setOpen(false)}>
                    <CloseIcon />
                </div>
                <h1>Buy tickets</h1>
                <div className='buyTicketDetails'>
                    <div className='eachBuyTicketDetail'>
                        <span>Ticket price</span>
                        <span>1.5 TON</span>
                    </div>
                    <div className='eachBuyTicketDetail'>
                        <span>Tickets amount</span>
                        <span>x4</span>
                    </div>
                    <div className='eachBuyTicketDetail'>
                        <span>Service fee</span>
                        <span>7%</span>
                    </div>
                </div>
                <div className='lineBreak' />
                <div className='eachBuyTicketDetail'>
                    <span>You pay</span>
                    <span>6.42 TON</span>
                </div>
                <div className='eachBuyTicketDetail'>
                    <span></span>
                    <span className='eachBuyTicketDetailSpan'>$15.79</span>
                </div>
                <div className='myTonCount'>
                    <div className='tonCountInput'>
                        <div className='tonCountInputLeft' onClick={() => handlePriceChange('minus')} style={tonCount === 1 ? { color: '#696D75' } : {}}>-</div>
                        <div className='tonCountInputCenter'>
                            <p>{tonCount}</p>
                            <span>1.1 TON / $2.39</span>
                        </div>
                        <div className='tonCountInputRight' onClick={() => handlePriceChange('plus')} style={tonCount === 99999 ? { color: '#696D75' } : {}}>+</div>
                    </div>
                    <div className='upToMax greyButton' onClick={() => setTonCount(99999)}>+MAX</div>
                </div>
                <button className='greenButton payWithWallet' onClick={() => {
                    setOpen(false)
                    setOpenLoading(true)
                }}>Pay with Wallet</button>
                <span className='payWithDeposit'>Pay with Deposit – 14.56 TON</span>
            </div>
        </div>
    )
}