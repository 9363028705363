import store from "../store/store"

export const GameReducer = (state = store, action) => {
    let temp = { ...state }
    switch (action.type) {
        case 'startGame':
            console.log('game payload --->>>', action.payload)
            break;
        default:
            return temp;
    }
    return temp;
}