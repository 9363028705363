import './style.css'
import { useEffect } from 'react'
import { CloseIcon } from '../svg'
import MoonLoader from "react-spinners/MoonLoader"

export const BuyTicketPopupLoading = ({ open, setOpen, setOpenQr }) => {

    useEffect(() => {
        open && myFunction()
    }, [open])

    function myFunction() {
        setTimeout(() => {
            setOpenQr(true)
            setOpen(false)
        }, 2000)
    }

    return (
        <div className={open ? 'active' : 'inactive'}>
            <div className='loginPopup'>
                <div className='closeLoginPopup' onClick={() => setOpen(false)}>
                    <CloseIcon />
                </div>
                <h1>Almost ready...</h1>
                <MoonLoader
                    className='loadingPopup'
                    color="#ffffff"
                    size={40}
                />
                <span className='loadingSpan'>Preparing data for sending the transaction to the blockchain</span>
            </div>
        </div>
    )
}