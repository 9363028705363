import store from "../store/store"

export const LoginReducer = (state = store, action) => {
    let temp = { ...state }
    switch (action.type) {
        case 'login':
            if (action.payload.status === 200) {
                localStorage.setItem('Cookie', action.payload.data.cookie)
            }
            // console.log('login payload --->>>', action.payload)
            break;
        default:
            return temp;
    }
    return temp;
}