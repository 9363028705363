import { useState } from 'react'
import './style.css'

export const RouletteFilter = () => {
    const [active, setActive] = useState(0)
    return (
        <div className='rouletteFilter'>
            <div className='eachRouletteFilter' style={active === 0 ? { background: '#292E3A' } : {}} onClick={() => setActive(0)}>
                <span style={active === 0 ? { color: '#ffffff' } : {}}>All</span>
            </div>
            <div className='eachRouletteFilter' style={active === 1 ? { background: '#292E3A' } : {}} onClick={() => setActive(1)}>
                <span style={active === 1 ? { color: '#ffffff' } : {}}>NFT</span>
            </div>
            <div className='eachRouletteFilter' style={active === 2 ? { background: '#292E3A' } : {}} onClick={() => setActive(2)}>
                <span style={active === 2 ? { color: '#ffffff' } : {}}>TON</span>
            </div>
        </div>
    )
}