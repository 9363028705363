export const TicketFilter = ({ active, setActive }) => {
    return (
        <div className='rouletteFilter ticketRouletteFilter'>
            <div className='eachRouletteFilter' style={active === 0 ? { background: '#292E3A' } : {}} onClick={() => setActive(0)}>
                <span style={active === 0 ? { color: '#ffffff', fontWeight: '600' } : { fontWeight: '600' }}>Upcoming</span>
            </div>
            <div className='eachRouletteFilter' style={active === 1 ? { background: '#292E3A' } : {}} onClick={() => setActive(1)}>
                <span style={active === 1 ? { color: '#ffffff', fontWeight: '600' } : { fontWeight: '600' }}>Winnings</span>
            </div>
            <div className='eachRouletteFilter' style={active === 2 ? { background: '#292E3A' } : {}} onClick={() => setActive(2)}>
                <span style={active === 2 ? { color: '#ffffff', fontWeight: '600' } : { fontWeight: '600' }}>Finished</span>
            </div>
        </div>
    )
}