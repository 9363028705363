import './style.css'
import { useState } from 'react'
import { BlueGreaterThan, Cancel, CloseIcon } from '../svg'

export const TransferTonPopup = ({ open, setOpen }) => {
    const [walletCode, setWalletCode] = useState('UQCcCVww_JjfENvGRuXf8Q-kJ7-cKBCvv8iWGrxKBCvv8iWGrxKBCvv8iWGrx')
    const [transferAmount, setTransferAmount] = useState(0.1)

    return (
        <div className={open ? 'active' : 'inactive'}>
            <div className='loginPopup transferPopup'>
                <div className='closeLoginPopup' onClick={() => setOpen(false)}>
                    <CloseIcon />
                </div>
                <h1>Transfer TON</h1>
                <div className='sendToWallet'>
                    <span>Send to wallet</span>
                    <input disabled value={walletCode} />
                </div>
                <div className='transferAmount'>
                    <span>Amount</span>
                    <div className='transferAmountRight'>
                        <div className='transferInput'>
                            <input placeholder='Amount' value={transferAmount} onChange={(e) => setTransferAmount(e.target.value)} />
                            <div className='cancelTransfer' onClick={() => setTransferAmount('')} >
                                <Cancel />
                            </div>
                        </div>
                        <span>Transfer fee 0.15 TON</span>
                    </div>
                </div>
                <span>NFT Club members do not have Transfer fee. <span className='transferLink'>Check out how to earn with our product <BlueGreaterThan /></span></span>
                <button className='greenButton transferButton'>Transfer</button>
            </div>
        </div>
    )
}