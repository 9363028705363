import { RouletteDiamond, TelegramIcon } from '../svg'
import './style.css'

export const FinishedRoulettes = () => {
    const roulettes = [
        {
            image: '1.png',
            title: 'G-BOT #4390',
            description: 'Emerald eyed G-BOT with a Golden Outer Body, Plastic Inner Body and Plastic Elements on Oxley Background. Featuring Origin Full Progenitor Set',
            cryptoAmount: '45',
            cryptoText: 'NFT price',
            soldAmount: '45',
            soldText: 'Tickets sold',
            id: '1',
            myTicketCount: '4',
            winnerAvatar: 'navAvatar.png',
            share: true,
            gameNumber: '342488',
            finishDate: '18:30, 23 Jan',
        },
        {
            image: '1.png',
            title: 'G-BOT #4390',
            description: 'Emerald eyed G-BOT with a Golden Outer Body, Plastic Inner Body and Plastic Elements on Oxley Background. Featuring Origin Full Progenitor Set',
            cryptoAmount: '45',
            cryptoText: 'NFT price',
            soldAmount: '45',
            soldText: 'Tickets sold',
            id: '1',
            myTicketCount: '0',
            winnerAvatar: 'navAvatar.png',
            share: false,
            gameNumber: '342488',
            finishDate: '18:30, 23 Jan',
        },
        {
            image: 'Group161.png',
            title: 'TON COIN Raffle',
            description: 'Emerald eyed G-BOT with a Golden Outer Body, Plastic Inner Body and Plastic Elements on Oxley Background. Featuring Origin Full Progenitor Set',
            cryptoAmount: '45',
            cryptoText: 'Total pool',
            soldAmount: '100',
            soldText: 'Tickets sold',
            id: '3',
            myTicketCount: '0',
            winnerAvatar: 'navAvatar.png',
            share: false,
            gameNumber: '342488',
            finishDate: '18:30, 23 Jan',
        },
    ]

    return (
        <div className='finishedRoulettes'>
            {roulettes.length > 0 && roulettes.map((e, i) => (
                <div className='eachFinishedRoulette' key={i}>
                    <div className='eachFinishedLeft'>
                        <img alt='' src={require(`../../public/${e?.image}`)} />
                        <div className='finishedRight'>
                            <h2>{e.title}</h2>
                            <p>{e.description}</p>
                            <div className='activeCryptos finishedCryptos'>
                                <div className='eachActiveCrypto'>
                                    <div className='cryptoIcon'>
                                        <RouletteDiamond />
                                        <span>{e.cryptoAmount}</span>
                                    </div>
                                    <p>{e.cryptoText}</p>
                                </div>
                                <div className='eachActiveCrypto'>
                                    <div className='cryptoIcon'>
                                        <RouletteDiamond />
                                        <span>{e.soldAmount}</span>
                                    </div>
                                    <p>{e.soldText}</p>
                                </div>
                                {e.myTicketCount > 0 &&
                                    <div className='eachActiveCrypto'>
                                        <div className='cryptoIcon'>
                                            <span>{e.myTicketCount}</span>
                                        </div>
                                        <p>Your tickets</p>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                    <div className='activeCryptos mobileFinishedCryptos'>
                        <div className='eachActiveCrypto'>
                            <div className='cryptoIcon'>
                                <RouletteDiamond />
                                <span>{e.cryptoAmount}</span>
                            </div>
                            <p>{e.cryptoText}</p>
                        </div>
                        <div className='eachActiveCrypto'>
                            <div className='cryptoIcon'>
                                <RouletteDiamond />
                                <span>{e.ticketAmount}</span>
                            </div>
                            <p>{e.ticketText}</p>
                        </div>
                        <div className='eachActiveCrypto'>
                            <div className='cryptoIcon'>
                                <span>{e.soldAmount}</span>
                            </div>
                            <p>{e.soldText}</p>
                        </div>
                    </div>
                    <div className='eachFinishedRight' style={e.share ? {} : { marginRight: '25px' }}>
                        <div className='eachFinishedWinner'>
                            <img className='winnerAvatar' alt='' src={require(`../../public/${e.winnerAvatar}`)} />
                            <img className='crown' alt='' src={require(`../../public/crown.png`)} />
                        </div>
                        {e.share &&
                            <div className='shareWin'>
                                <TelegramIcon />
                                <span>Share win</span>
                            </div>
                        }
                    </div>
                    <div className='mobileFinishedDetails'>
                        <div className='finishedDetailsLeft'>
                            <div className='eachFinishedDetailsLeft'>
                                <span>Game number:</span>
                                <p style={{ textDecorationLine: 'underline' }}>{e.gameNumber}</p>
                            </div>
                            <div className='eachFinishedDetailsLeft'>
                                <span>Raffle finished:</span>
                                <p>{e.finishDate}</p>
                            </div>
                        </div>
                        <div className='eachFinishedWinner'>
                            <img className='winnerAvatar' alt='' src={require(`../../public/${e.winnerAvatar}`)} />
                            <img className='crown' alt='' src={require(`../../public/crown.png`)} />
                        </div>
                    </div>
                </div>
            ))}
            <button className='transparentButton'>See more</button>
        </div>
    )
} 