import './style.css'
import { useState } from 'react'
import { PlusSign, MinusSign } from '../svg'

export default function HowToPlayAccordion() {
    const tips = [
        {
            title: 'Point 1',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
        },
        {
            title: 'Point 2',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
        },
        {
            title: 'Point 3',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
        },
        {
            title: 'Point 4',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
        },
        {
            title: 'Point 5',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
        },
        {
            title: 'Point 6',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
        },
    ]
    const [expandedAccordion, setExpandedAccordion] = useState([])

    function handleClick(i) {
        let index = expandedAccordion.findIndex((x) => x === i);
        if (index === 0) {
            expandedAccordion.splice(index, 1);
        } else {
            expandedAccordion.push(i)
        };
        setExpandedAccordion(oldArray => [...oldArray])
    }

    return (
        <div className='accordion'>
            {tips.length > 0 && tips.map((e, i) => (
                <div className='eachAccordionItem' key={i}>
                    <div className='eachAccordionTitle' onClick={() => handleClick(i)}>
                        <span>{e.title}</span>
                        <PlusSign />
                    </div>
                    <p style={expandedAccordion.includes(i) ? { visibility: 'visible', height: '25px' } : { visibility: 'hidden', height: 0 }}>{e.text}</p>
                </div>
            ))}
        </div >
    )
}