import './style.css'
import { useState } from 'react'
import { BlueGreaterThan, Cancel, DiamondFund } from '../svg'


export const TwoBets = () => {
    const [betStake, setBetStake] = useState(0.1)

    return (
        <div className='betOptions'>
            <span>Game ends in <img className='sandHour' alt='' src={require('../../public/sandHour.png')} /> 00:47</span>
            <div className='betBg'>
                <div className='myBet'>
                    <span>Your bet</span>
                    <span>$270.39</span>
                </div>
                <span className='winChance'><DiamondFund />107 with <span className='winPercent'>76%</span>win chance</span>
                <div className='upMyBet'>
                    <div className='inputBet'>
                        <div className='myBetInput'>
                            <input placeholder='Your value' type='number' value={betStake} onChange={(e) => setBetStake(e.target.value)} />
                            <div className='cancelBet' onClick={() => setBetStake('')} >
                                <Cancel />
                            </div>
                        </div>
                        <div className='upBet' onClick={() => setBetStake(+betStake + 0.1)}>
                            <span>+0.1</span>
                        </div>
                        <div className='upBet' onClick={() => setBetStake(+betStake + 1)}>
                            <span>+1</span>
                        </div>
                        <div className='upBet' onClick={() => setBetStake(+betStake + 5)}>
                            <span>+5</span>
                        </div>
                    </div>
                </div>
                <div className='submitMyBet'>
                    <button className='stakeMyBet greenButton'>Stake <span><img alt='' src={require('../../public/fire.png')} /></span></button>
                    <div className='myBetBalance'>
                        <p>Your balance: 15.44 TON</p>
                        <span>Deposit <BlueGreaterThan /></span>
                    </div>
                </div>
            </div>
        </div>
    )
}