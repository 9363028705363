import './style.css'
import { Sidebar } from '../sidebar'
import { Outlet } from 'react-router-dom'
import { Navigation } from '../navigation'
import { Footer } from '../../component/footer'

export const Layout = () => {
    return (
        <div className='layout'>
            <Navigation />
            <div className='layoutSide'>
                <Sidebar />
                <div className='outlet'>
                    <Outlet />
                    <Footer />
                </div>
            </div>
        </div>
    )
}