import { ArrowRight } from '../svg'
import './style.css'

export const HomeLotteries = () => {
    const lotteries = [
        {
            title: 'NFT Raffle',
            description: 'Get a chance of winning an exclusive NFT',
            link: 'Buy tickets',
            id: 1,
        },
        {
            title: 'TON Raffle',
            description: 'Your everyday dose of TON raffles',
            link: 'Buy tickets',
            id: 2,
        },
        {
            title: 'TON Roulette',
            description: 'Classic PVP roulette',
            link: `Make a bet`,
            id: 3,
        },
        {
            title: 'TON Lottery',
            description: 'Day-to-day growing super prize!',
            link: 'Release soon...',
            id: 4,
        },
    ]
    return (
        <div className='homeLotteries'>
            {lotteries.length > 0 && lotteries.map((e, i) => (
                <div className='eachLottery' key={i}>
                    <div className='eachLotteryTop' />
                    <div className='eachLotteryBottom'>
                        <h3>{e.title}</h3>
                        <h4>{e.description}</h4>
                        {e.link === 'Release soon...'
                            ? <h6 onClick={() => window.location = `/buy/${e.id}`}>{e.link}</h6>
                            : <h5 onClick={() => window.location = `/buy/${e.id}`}>{e.link} <ArrowRight /> </h5>
                        }
                    </div>
                </div>
            ))}
        </div>
    )
}