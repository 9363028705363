import './style.css'
import { useState } from 'react'
import { MyBet } from '../myBet'
import { NoBets } from '../noBets'
import { BetsWon } from '../betsWon'
import { TwoBets } from '../twoBets'
import { BetsLose } from '../betsLose'
import { BetsRolling } from '../betsRolling'
import { BetsNoPlayed } from '../betsNoPlayed'

export const BetPlayers = () => {
    const [bets, setBets] = useState({
        noBets: false,
        myBet: true,
        twoBets: false,
        betsRolling: false,
        betsWon: false,
        betsLose: false,
        betsNoPlayed: false,
    })

    return (
        <>
            <div className='rouletteOptions'>
                {bets.noBets && <span className='mobileTime'>Start game with your stake <img className='sandHour' alt='' src={require('../../public/manHat.png')} /></span>}
                {bets.myBet && <span className='mobileTime'>Waiting for 1 more player...</span>}
                {bets.twoBets && <span className='mobileTime'>Game ends in <img className='sandHour' alt='' src={require('../../public/sandHour.png')} /> 00:47</span>}
                {bets.betsRolling && <span className='mobileTime'>Waiting roll results...</span>}
                {bets.betsWon && <span className='mobileTime'>You win <img className='sandHour' alt='' src={require('../../public/grats.png')} /> Grats!</span>}
                {bets.betsLose && <span className='mobileTime'>You lose <img className='sandHour' alt='' src={require('../../public/lose.png')} /> Try again to win!</span>}
                {bets.betsNoPlayed && <span className='mobileTime'>Round finished</span>}

                <div className='betDiagram'>
                    <img alt='' src={require('../../public/CIRCLE.png')} />
                </div>

                {!bets.noBets && <div className='betPlayers'>
                    <div className='eachBetPlayer'>
                        <img alt='' src={require('../../public/navAvatar.png')} />
                        <span className='eachPlayerChance'>107 <img alt='' src={require('../../public/diamond.png')} />= 76% </span>
                    </div>
                    <div className='eachBetPlayer' style={{ border: '0.5px solid #F4B50F' }}>
                        <img alt='' src={require('../../public/player2.png')} style={{ border: '0.5px solid #F4B50F' }} />
                        <span className='eachPlayerChance'>31 <img alt='' src={require('../../public/diamond.png')} />= 24% </span>
                    </div>
                </div>}

                {bets.noBets && <NoBets />}
                {bets.myBet && <MyBet />}
                {bets.twoBets && <TwoBets />}
                {bets.betsRolling && <BetsRolling />}
                {bets.betsWon && <BetsWon />}
                {bets.betsLose && <BetsLose />}
                {bets.betsNoPlayed && <BetsNoPlayed />}
                
            </div>
            {!bets.noBets && <div className='betPlayersWeb'>
                <div className='eachBetPlayerWeb'>
                    <img alt='' src={require('../../public/navAvatar.png')} />
                    <div className='webBetChance'>
                        <span className='eachPlayerChanceWeb'>107 <img alt='' src={require('../../public/diamond.png')} />= 76% </span>
                        <span className='webPrice'>$270.39</span>
                    </div>
                </div>
                <div className='eachBetPlayerWeb'>
                    <img alt='' src={require('../../public/player2.png')} style={{ border: '0.5px solid #F4B50F' }} />
                    <div className='webBetChance'>
                        <span className='eachPlayerChanceWeb'>31 <img alt='' src={require('../../public/diamond.png')} />= 24% </span>
                        <span className='webPrice'>$270.39</span>
                    </div>
                </div>
            </div>}
        </>
    )
}