import './style.css'
import HowToPlayAccordion from '../../component/howToPlayAccordion'
import { useState } from 'react'
import { HowToPlay } from '../../component/howToPlay'
import { DiamondFund, GreaterThan, RouletteDiamond } from '../../component/svg'

export const SingleRaffle = () => {
    const [value, setValue] = useState(10)

    return (
        <div className='singleRaffleList eachPage'>
            <div className='backToRaffleList'>
                <span onClick={() => window.location = '/roulettes'}>&lt; Back to raffle list</span>
            </div>
            <div className='singleRaffHeadingImg'>
                <h1>G-BOT #4390</h1>
                <img alt='' src={require('../../public/Group161.png')} />
                <div className='singleRaffHeading'>
                    <h1>G-BOT #4390</h1>
                    <p>Raffle short description Emerald eyed G-BOT with a Golden Outer Body,
                        Plastic Inner Body and Plastic Elements on Oxley Background. Featuring
                    </p>
                    <div className='singleCryptos'>
                        <div className='eachUpcomingCrypto'>
                            <div className='cryptoIcon upcomingCrypto'>
                                <RouletteDiamond />
                                <span>45</span>
                            </div>
                            <p>Prize</p>
                        </div>
                        <div className='eachUpcomingCrypto'>
                            <div className='cryptoIcon upcomingCrypto'>
                                <RouletteDiamond />
                                <span>1</span>
                            </div>
                            <p>Ticket price</p>
                        </div>
                        <div className='eachUpcomingCrypto'>
                            <div className='cryptoIcon upcomingCrypto'>
                                <span>10 / 45</span>
                            </div>
                            <p>Tickets sold</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='takeYourChance'>
                <h2>Take your chance!</h2>
                <p>Raffle will start when all tickets will be sold</p>
                <span className='progress_parent'>
                    <input type="range" className='progress_bar' onChange={() => setValue()} max={45} value={10} style={{ background: `linear-gradient(90deg, #a80b98 0% ${value}% , #2C3340 ${value}% 100% )` }} />
                    <span className='progressValue'>10 / 45 tickets sold</span>
                </span>
                <button className='greenButton buyThisTicket'>Buy ticket</button>
            </div>
            <div className='raffleName'>
                <div className='raffleNameLeft'>
                    <h2>TON G-BOTS SD</h2>
                    <p>TON is a blockchain ecosystem created from the ground up to make digital life easier.
                        If you’re an NFT artist or a collector, TON is the deal tool for you thanks to its
                        high performance and low transaction fees. And it’s eco-friendly.
                    </p>
                    <span>gbots.store <GreaterThan /> </span>
                </div>
                <div className='raffleNameRight'>
                    <div className='raffleNameRightTop'>
                        <div className='eachRaffleName'>
                            <span>Total value</span>
                            <div className='eachRaffleNameIcon'>
                                <DiamondFund />
                                <p>7.9M</p>
                            </div>
                        </div>
                        <div className='eachRaffleName'>
                            <span>Floor price</span>
                            <div className='eachRaffleNameIcon'>
                                <DiamondFund />
                                <p>20</p>
                            </div>
                        </div>
                        <div className='eachRaffleName'>
                            <span>Items</span>
                            <div className='eachRaffleNameIcon'>
                                <p>6.7K</p>
                            </div>
                        </div>
                    </div>
                    <div className='raffleNameRightTop'>
                        <div className='eachRaffleName'>
                            <span>Owners</span>
                            <div className='eachRaffleNameIcon'>
                                <p>1479</p>
                            </div>
                        </div>
                        <div className='eachRaffleName'>
                            <span>Unique holders</span>
                            <div className='eachRaffleNameIcon'>
                                <p>22.18%</p>
                            </div>
                        </div>
                        <div className='eachRaffleName'>
                            <span>Avg. owned</span>
                            <div className='eachRaffleNameIcon'>
                                <p>4.5</p>
                            </div>
                        </div>
                    </div>
                    <span>Updated on 29 Jan, 8:00 AM</span>
                </div>
            </div>
            <HowToPlay />
            <HowToPlayAccordion />
            <div className='singleBanners'>
                <div className='eachSingleBanner' />
                <div className='eachSingleBanner' />
            </div>
        </div>
    )
}