import './style.css'
import { RouletteTickets } from '../../component/rouletteTickets'
import { RouletteFilter } from '../../component/rouletteFilter'
import { ActiveRoulettes } from '../../component/activeRoulettes'
import { FinishedRoulettes } from '../../component/finishedRoulettes'

export const Roulettes = () => {
    return (
        <div className='roulettes eachPage'>
            <RouletteTickets />
            <h1>Active raffles</h1>
            <RouletteFilter />
            <ActiveRoulettes />
            <h1>Finished raffles</h1>
            <FinishedRoulettes />
        </div>
    )
}