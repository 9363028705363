import './style.css'
import { HomeGuide } from '../../component/homeGuide'
import { HomeRoulette } from '../../component/homeRoulette'
import { HomeLotteries } from '../../component/homeLottery'

export const Home = () => {
    return (
        <div className='home eachPage'>
            <HomeGuide />
            <h2>Try our games</h2>
            <HomeRoulette />
            <h2 className='HomeGames'>Try our games</h2>
            <HomeLotteries />
        </div>
    )
}